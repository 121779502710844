.filter-sticky {
    &:not(.no-overlay) {
        &:before {
            position: absolute;
            content: " ";
            left: -100%;
            width: 100%;
            height: 110%;
            top: -10%;
        }

        &:after {
            position: absolute;
            content: " ";
            right: -100%;
            width: 100%;
            height: 110%;
            top: -10%;
        }
    }
}

.filter-bar {
    color: $white;
    display: flex;
    border: none;
    flex-direction: row;
    justify-content: space-between;
    height: 4.25rem;
    padding: 0.75rem $card-spacer-x;

    &:before {
        content: '';
        display: block;
        background: $white;
        height: 10px;
        position: absolute;
        top: -10px;
        left: 0;
        right: 0;
        width: 100%;
    }

    &--tertiary {
        height: auto;
        padding: 1rem 1.5rem;
    }

    &__toggle {
        display: flex;
        align-items: center;

        &--specific-filter-available {
            &:after {
                margin-left: 0.5rem;
                display: block;
                font-size: 1.5rem;
                line-height: 1rem;
                content: '›';
                transform: rotate(90deg);
            }
        }


        &--active {
            &:after {
                transform: rotate(-90deg);
            }
        }
    }

    &__actions {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: row;
        margin: 0 0 0 auto;

        &.spaced {
            width: 100%;
            margin: 0;
            justify-content: space-between;
        }

        &.left-aligned {
            width: 100%;
            margin: 0;
            justify-content: flex-start;
        }
    }

    &__action {
        border-left: 1px solid rgba(255, 255, 255, 0.25);
        padding: 0 1rem;
        display: flex;
        align-items: center;

        &:first-child {
            border-left: none;
        }

        &:last-child {
            padding-right: 0;
        }

        &.right-aligned {
            margin-left: auto;
        }

        &.primary {
            padding-right: 1rem;
        }

        &.action-success {
            color: $brand-success;
        }

        &.action-warning {
            color: lighten($brand-warning, 5%);
        }

        &.action-danger {
            color: lighten($brand-danger, 10%);
        }
    }

    &__info {
        padding: 0 1rem;
        display: flex;
        align-items: center;
        justify-self: center;
        justify-content: center;
        flex: 1 1 auto;
    }

    &__action-link {
        color: inherit;
        text-decoration: none;

        &:hover,
        &:focus {
            color: inherit;
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        &.link-caret {
            color: $link-color;
            font-weight: normal;

            &:after {
                content: '›';
                font-weight: bold;
                padding-left: 0.25rem;
            }
        }
    }

}

.range-filter {

    &__row {
        @for $i from 1 through 12 {
            .col-#{$i} {
                display: flex;
                align-items: stretch;
            }
        }
    }

    &__stretch {
        display: flex;
        align-items: stretch;
        width: 100%;
    }

    &__center {
        width: 100%;
        display: flex;
        align-items: center;
    }

    label {
        margin: 0 4px 0 4px;

        &.right-input-label {
            min-width: 1em;
        }
    }
}

.filter-checkbox-tmc__warning {
    color: red;
    padding-top: 5px;
}

.mat-button-toggle-group {
    .mat-button-toggle {
        color: $body-color !important;
        font-family: $font-family-sans-serif !important;

        &.mat-button-toggle-checked:not(.mat-button-toggle-disabled) {
            color: white !important;
            background-color: $brand-primary !important;
        }
    }

    .mat-button-toggle-label-content {
        padding: 0 8px !important;
        line-height: 28px !important;
    }

    .mat-menu-trigger {
        border-left: solid 1px rgba(0, 0, 0, .12);
        border-radius: 0;
        line-height: normal;
        height: auto;
        width: auto;

        &:hover {
            .mat-button-focus-overlay {
                opacity: 0.04;
            }
        }
    }
}

.app-filter-radio-button-group {
    &.select-less-than.has-selection {
        .mat-button-toggle:not(.mat-button-toggle-checked) {
            color: white !important;
            background-color: $brand-primary;
            opacity: 0.8;
        }

        .mat-button-toggle.mat-button-toggle-checked ~ .mat-button-toggle {
            color: $body-color !important;
            background-color: white;
        }
    }

    .mat-button-toggle-group {
        border: 0;
        border-radius: 0;
        flex-wrap: wrap;

        .mat-button-toggle {
            margin-bottom: 0.2rem;
            border-top: solid 1px rgba(0, 0, 0, .12);
            border-bottom: solid 1px rgba(0, 0, 0, .12);

            &:first-child {
                border-left: solid 1px rgba(0, 0, 0, .12);
            }

            &:nth-child(8), &:last-child {
                border-right: solid 1px rgba(0, 0, 0, .12);
            }

            &.mat-button-toggle-checked.default-button {
                background-color: $gray-lighter !important;
                color: $gray-light !important;
            }

            &.mat-button-toggle-disabled {
                opacity: 0.4;
            }
        }
    }
}

.container-disabled {
    user-select: none;
    opacity: 0.2;

    * {
        pointer-events: none;
        user-select: none;
    }
}
