@use "sass:math";

$spinner-animation-duration: 4s;
$spinner-box-count: 8;

.spinner-wrapper {
    display: flex;
    justify-content: center;
}

.spinner {
    $b: &;

    &__box {
        width: 0.75rem;
        height: 0.75rem;
        background: $dericon-medium-light-grey;
        animation: spinner $spinner-animation-duration infinite ease-in-out;

        margin: 0 0.25rem;

        @for $i from 0 to $spinner-box-count {
            &:nth-child(#{$i}) {
                animation-delay: math.div($spinner-animation-duration * $i, $spinner-box-count);
            }
        }
    }

    &--inverse {

        #{$b}__box {
            background: $white;
            opacity: 0.25;
            animation: spinner-inverse $spinner-animation-duration infinite ease-in-out;

            @for $i from 0 to $spinner-box-count {
                &:nth-child(#{$i}) {
                    animation-delay: math.div($spinner-animation-duration * $i, $spinner-box-count);
                }
            }
        }

    }


    &--circle {
        &__box {
            display: flex;
            height: 1.4rem;
            text-align: center;
            font-size: 10px;
            align-items: center;
            justify-content: center;
            padding-right: 2px;

            & > div {
                background-color: currentColor;
                height: 100%;
                width: 4px;
                display: inline-block;
                margin-left: 1px;

                -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
                animation: sk-stretchdelay 1.2s infinite ease-in-out;
            }

            .rect2 {
                -webkit-animation-delay: -1.1s;
                animation-delay: -1.1s;
            }

            .rect3 {
                -webkit-animation-delay: -1.0s;
                animation-delay: -1.0s;
            }

           .rect4 {
                -webkit-animation-delay: -0.9s;
                animation-delay: -0.9s;
            }

            .rect5 {
                -webkit-animation-delay: -0.8s;
                animation-delay: -0.8s;
            }
        }
    }
}

@keyframes spinner {

    $step1: math.div(200, $spinner-box-count);
    $step2: math.div(100, $spinner-box-count);

    0%, #{$step1}% {
        background: $dericon-medium-light-grey;
    }

    #{$step2}% {
        background: $brand-primary;
    }

}


@keyframes spinner-inverse {

    $step1: math.div(200, $spinner-box-count);
    $step2: math.div(100, $spinner-box-count);

    0%, #{$step1}% {
        opacity: 0.25;
    }

    #{$step2}% {
        opacity: 1;
    }

}


@-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
    20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
    0%, 40%, 100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }
    20% {
       transform: scaleY(1.0);
       -webkit-transform: scaleY(1.0);
    }
}
