.custom-pagination-controls {
    display: flex;
    align-items: center;

    &__items-per-page {
        margin-right: 2rem;
        color: $brand-primary;
    }

    &__items-per-page-link {
        margin-left: 0.5rem;
        color: $text-muted;

        &--active {
            color: $brand-primary;
        }
    }
}


.pagination {
    color: $brand-primary;
    align-items: center;

    &__button {
        @extend .icon-button;
        &--previous {
            margin-right: 0.5rem;
        }

        &--next {
            margin-left: 0.5rem;
        }
    }

}
