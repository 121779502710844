.product-details-tabs {
    .tab-bar-container {
        margin-bottom: 2rem;
    }
}


.product-attachments {

    &__list {
        list-style: none;
        padding: 0 !important;
        margin: 0;
        margin-bottom: 2rem;
    }

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 0.5rem;

        &--uploading {
            display: block;
        }

        &--failed {
            color: $brand-danger;
        }
    }

    &__remove-button {
        margin-left: 5px;
        padding: 0;
        font-size: 1rem;
    }

    &__label {
        display: flex;
        align-items: baseline;
        width: 85%;
        word-break: break-word;
        word-wrap: break-word;

        app-icon {
            position: relative;
            top: 6px;
        }
        
        .mat-icon {
            height: 22px;
            width: 22px;
            margin-right: 4px;
        }
    }
}

.product-changelog {
    &__horizontal-line {
        border-right: 1px solid $brand-primary;
    }

    &__group:not(:last-child) {
        padding-bottom: 1rem;
    }

    .list-group {
        display: inline-block;

        .list-group-item {
            
            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    p {
        margin-bottom: 0;
    }
}
