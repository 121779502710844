.criteria-selector {
    display: block;
    overflow: hidden;
}


.view-customer {
    display: block;

    &__form-placeholder {
        position: relative;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(255, 255, 255, 0.5);
        }
    }

}

.customer-selector {

    &__input {
        margin-bottom: 0.5rem;
    }

    &__customer-detail {
        font-size: $font-size-sm;
        margin-bottom: 0.5rem;
    }
}

app-simple-customer-selector {
    .form-group {
        .input-label {
            color: $brand-primary;
        }
    }
}
