.compact-layout,
.compact-layout ~ .cdk-drag-preview {
    .navbar.header-bar {
        padding: 1rem 0 1rem 0;
    }

    .slide-down {
        .entity-highlight__content {
            font-size: $font-size-h6;
        }

        &__controls {
            top: 2.5rem;
        }

        &__content {
            > * {
                padding-top: 2rem;
                padding-bottom: 1rem;
            }

            &--open {
                margin-bottom: 0;
            }
        }

        app-change-password-form {
            .btn {
                font-size: $font-size-sm;
                padding: 0.3rem 0.5rem;
            }
        }
    }

    /*.menu {
        &--inline {
            .menu__row {
                height: auto;
            }

            .menu-item {
                width: auto;

                &__link {
                    display: flex;
                    align-items: center;

                    > * {
                        display: flex;
                        height: auto;
                        margin: inherit;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }*/

    .card {
        .card-form__section {
            .card-headline {
                font-size: $font-size-h6;
                margin-bottom: 0.6rem;
            }
        }

        .card-title {
            margin-bottom: 1.2rem;
        }
    }

    .entity-table tbody {
        th, td {
            padding: 0.375rem;
        }
    }

    /*.widget-container {
        &--responsive {
            .entity-widget {
                &--col-1 {
                    @include make-col(1, 4);

                    @include media-breakpoint-up('xxl') {
                        @include make-col(1, 5);
                    }
                }

                &--col-2 {
                    @include make-col(2, 4);

                    @include media-breakpoint-up('xxl') {
                        @include make-col(2, 5);
                    }
                }

                &--col-3 {
                    @include make-col(3, 4);

                    @include media-breakpoint-up('xxl') {
                        @include make-col(3, 5);
                    }
                }

                &--col-4 {
                    @include make-col(4, 4);

                    @include media-breakpoint-up('xxl') {
                        @include make-col(4, 5);
                    }
                }

                &--col-5 {
                    @include make-col(4, 4);

                    @include media-breakpoint-up('xxl') {
                        @include make-col(5, 5);
                    }
                }
            }
        }
    }*/

    app-product-details-overlay, app-entity-widget-container {
        /*.product-details-tabs .tab-bar-container {
            margin-bottom: 1.5rem;
        }

        .product-selection-overlay__actions .actions-container {
            margin-bottom: 1rem;
        }*/

        .entity-widget__card--flat .card-block {
            padding: 0;
        }

        .entity-details-table__table tbody tr {
            th, td {
                padding: 0.4rem 0.375rem 0.2rem 0.375rem;
            }

            th {
                padding-left: 0.05rem;
            }
        }
    }

    .focus-list-widget {
        margin-left: -1.45rem;
        margin-right: -1.45rem;
        margin-top: -1.45rem;
        margin-bottom: -0.1rem;
    }

    .image-news-widget {
        &__image {
            height: 110%;

            &--LEFT {
                margin: -1.25rem;
            }

            &--RIGHT {
                margin-top: -1.25rem;
                right: -1.25rem;
                position: relative;
            }

            &--TOP {
                margin: -1.25rem -2.5rem 1.25rem -1.25rem;
                width: 115%;
            }

            &--BOTTOM {
                margin: 1.25rem -2.5rem -1.25rem -1.25rem;
                width: 115%;
            }
        }
    }
}
