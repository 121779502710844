.rfq-issuer-offer-page {
    &__loading {
        &--center {
            position: relative;
            width: 100%;
            padding: 1rem;
            text-align: center;
        }

        &--right {
            position: absolute;
            right: 0;
            padding-top: 2.5rem;
            text-align: right;
        }
    }
    &__content__form {
        display: block;

        .input-group {
            align-items: center;
            flex-wrap: wrap;

            .input-group-append {
                padding-left: 1rem;
            }

            .input-group-attach {
                padding-top: 1rem;
                flex-basis: 100%;
                text-align: right;
                font-size: 0.8rem;
            }
        }
    }

    &__content {
        .card-form__section > .card-headline.control-label {
            font-size: 1rem!important;
        }
    }

    .filter-bar__actions {
        list-style: none;
        padding: 0;
        display: flex;
        width: 100%;
        flex-direction: row;
        margin: 0;
        justify-content: flex-end;

        .filter-bar__action {
            a.link-icon {
                app-icon {
                    mat-icon.material-icon {
                        height: 20px;
                        width: 20px;
                        bottom: -4px;
                        left: -3px;
                    }
                }
            }

            &:last-child {
                padding-right: 1rem;
            }
        }
    }

    &__content__tabs {
        text-align: center;

        &__panel {
            &__offer {
                border: 1px solid $brand-primary;
                padding: 1rem;
            }

            &__decline {

            }
        }
    }


    &__issuer-contact {
        &__actions {
            display: flex;
            justify-content: center;
        }

        &__action-link {
            padding: 0.2rem;
            display: flex;
            align-items: center;
            margin-right: 0.5rem;
            text-decoration: none;

            &.primary {
                font-weight: bold;

                &:after {
                    content: '›';
                    font-weight: bold;
                    padding-left: 0.25rem;
                }
            }
        }
    }

    &__request__details {
        &__row {
            padding-bottom: 0.2rem;
            padding-top: 0.4rem;
            border-bottom: 1px solid #eceeef;
        }

        &__title {
            align-self: center;

            .card-headline {
                font-size: 0.9rem !important;
                margin-bottom: 0!important;
            }
        }

        &__value {
            align-self: center;
        }
    }
}

.rfq-issuer-variance-combination-preview {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: -1rem;
    padding-right: 2rem;
    padding-bottom: 1rem;

    &__item {
        display: flex;
        flex-direction: column;
        margin-right: 1rem;
        margin-bottom: 1rem;

        &__label {
            font-weight: bold;
            padding-bottom: 0.5rem;
        }
    }

    &__table {
        background-color: #fff;

        th, td {
            padding: 0.4rem 0.75rem 0.2rem 0.75rem;
        }
    }
}
