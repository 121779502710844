/**
 * Styles for dashboard and dashboard widgets
 */

 .dashboard-widget {
    margin-bottom: $grid-gutter-width-base;

    .card-block {
        display: flex;
        flex-direction: column;
    }
}

.additional-news-widget {

    &__list {
        list-style: none;
        padding: 0;
        margin-top: $card-headline-margin-y;
    }

    &__item {
        margin-bottom: $additional-news-widget-item-seperation;
    }

    &__item-date {
        font-size: $font-size-sm;
        color: $dericon-medium-grey;
    }

    &__item-headline {
        margin-top: 0.25rem;
    }

}

.row.flex-column,
.row.flex-column-reverse {
    .col {
        flex-basis: auto;
    }
}


.splide__slide {
    &.is-visible {
        .entity-widget-market-indicator__item {
            border-right: 1px solid $dericon-medium-light-grey;
        }

        &.is-last {
            .entity-widget-market-indicator__item {
             border-right: none;
            }
        }
    }
}

.entity-widget-market-indicator {
    &__item {
        // width: 240px;
        padding: 10px 14px 10px 5px;
        line-height: 1.5;
        height: 100%;
        display: flex;
        flex-direction: column;

        &.no-border {
            border-right: 0;
        }

        &__name {
            display: block;
            font-size: $font-size-base;
            text-transform: uppercase;
            color: $brand-primary;
        }

        &__value {
            display: block;
            font-size: $font-size-base;
        }

        &__change {
            margin-top: auto;
            display: inline-block;
            font-size: $font-size-base;
            color: red;

            &.positive {
                color: green;
            }
        }

        &__indicator {
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 6px 0 6px;
            border-color: red transparent transparent transparent;
            margin-right: 5px;
            margin-bottom: 2px;

            &.positive {
                border-width: 0 6px 8px 6px;
                border-color: transparent transparent green transparent;
            }
        }
    }

    &__prev-btn {
        position: absolute;
        right: 0.7rem;
        top: 50%;
        left: auto;
        transform: translateY(-110%);
    }

    &__next-btn {
        position: absolute;
        right: 0.7rem;
        left: auto;
        top: 50%;

        &.splide__arrow {
            transform: translateY(0%);
        }
    }

    &__next-btn,
    &__prev-btn {
        &.splide__arrow {
            border-radius: 0;
        }
    }
}

.entity-widget-market-data {
    .align-both-center {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        height: 100%;
    }

    &__item__prevDayPerformance {
        color: red;

        &.positive {
            color: green;
        }
    }

    .dynamic-chart--legend-below .ngx-charts-outer .ngx-charts {
        overflow: visible!important;
    }

    &__table-column {
        padding-bottom: 0.5rem;
    }

    &__chart-column {
        padding-bottom: 0.5rem;

        .chart-container {
            background: white;
            padding: 0.5rem;
            height: 100%;
        }
    }

    &__filter-buttons {
        text-align: center;
        padding-top: 1rem;

        .mat-button-toggle-group .mat-button-toggle-label-content {
            line-height: 22px !important;
            padding-right: 5px !important;
            padding-left: 5px !important;
        }
    }

    &__link {
        color: $body-color;
    }
}

@for $i from 1 through 2 {
    $colors: ("positive": green, "negative": red);

    .md-live-data--rising-#{$i} span {
        animation:bgColorChangeRising#{$i} .6s ease-in alternate both;
    }

    .md-live-data--falling-#{$i} span {
        animation:bgColorChangeFalling#{$i} .6s ease-in alternate both;
    }

    @each $class, $color in $colors {
        .md-live-data--rising-#{$i}.#{$class} span {
            animation:bgColorChangeRising#{$class}#{$i} .6s ease-in alternate both;
        }

        .md-live-data--falling-#{$i}.#{$class} span {
            animation:bgColorChangeFalling#{$class}#{$i} .6s ease-in alternate both;
        }
    }

    @keyframes bgColorChangeRising#{$i} {
        0% {
            color:$body-color;
            background-color:rgba(40,150,70,0)
        }
        30%,
        85% {
            color:#289646;
            background-color:rgba(40,150,70,.2)
        }
        to {
            color:$body-color;
            background-color:rgba(40,150,70,0)
        }
    }

    @keyframes bgColorChangeFalling#{$i} {
        0% {
            color:$body-color;
            background-color:rgba(40,150,70,0)
        }
        30%,
        85% {
            color:#c83232;
            background-color:rgba(200,50,50,.2)
        }
        to {
            color:$body-color;
            background-color:rgba(40,150,70,0)
        }
    }

    @each $class, $color in $colors {
        @keyframes bgColorChangeRising#{$class}#{$i} {
            0% {
                color:$color;
                background-color:rgba(40,150,70,0)
            }
            30%,
            85% {
                color:#289646;
                background-color:rgba(40,150,70,.2)
            }
            to {
                color:$color;
                background-color:rgba(40,150,70,0)
            }
        }

        @keyframes bgColorChangeFalling#{$class}#{$i} {
            0% {
                color:$color;
                background-color:rgba(40,150,70,0)
            }
            30%,
            85% {
                color:#c83232;
                background-color:rgba(200,50,50,.2)
            }
            to {
                color:$color;
                background-color:rgba(40,150,70,0)
            }
        }
    }
}
