/**
 * Styles for layout components
 */

.layout {

    &--inverse {
        background: $brand-primary;
        color: $white;

        a {
            color: $white;

            &:hover,
            &:active,
            &:focus {
                color: $white;
                text-decoration: underline;
            }

        }
    }

}

.default-layout {
    display: block;
    padding-bottom: $layout-padding-bottom;
}

.landing-layout {
    display: block;
    height: 100%;
    background: $brand-primary;
}

.external-layout {
    &__content {
        min-height: 100%;
        width: 100%;
        background: white;
    }

    &.muted-background {
        background: $brand-muted;
    }
}

.auth-layout {
    display: flex;
    height: 100%;
    flex-direction: column;

    &__header {
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__content {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

