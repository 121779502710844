.nav-tabs {

    .nav-item {
        text-transform: uppercase;
        letter-spacing: 0.0625rem;
    }

    .nav-link {
        border: none;
        color: $body-color;
        padding-left: 0;
        padding-right: 0;
        margin-right: $nav-tabs-distance;

        @include hover-focus {
            color: $nav-tabs-active-link-color;
        }
    }

    .nav-link.active,
    .nav-item.show .nav-link {
        background: transparent;
        color: $nav-tabs-active-link-color;
        border-bottom: $nav-tabs-active-border-width solid currentColor;
    }
}
