.toast-container {
    .ngx-toastr {
        font-family: Tahoma, Geneva, sans-serif;
        font-size: 12px;
        border-radius: 0;
        background-color: $brand-primary;
        color: $white;
        padding: 1rem;
        width: 314px !important;
    
        .toast-title {
            font-weight: normal;
            margin-bottom: 0.5rem;
        }
    
        .toast-close-button {
            top: -0.9rem;
            right: -0.5rem;
            font-size: 17px;
            font-weight: 100;
    
            &:hover,
            &:focus {
                color: $white;
            }
        }
    }

    .toast-success {
        background-color: $toast-success-color;
        padding-left: 3.5rem;
    
    
        background-size: 30px;
        background-position: 13px 15px;
        background-repeat: no-repeat;
    }

    .toast-error {
        background-color: $toast-error-color;
        padding-left: 3.5rem;
    
        background-size: 30px;
        background-position: 13px 15px;
        background-repeat: no-repeat;
    }
    
}
