.admin-header-bar {
    padding: 1rem 0;
    display: flex;
    align-items: center;

    &__logo {
        display: block;
        margin-right: 6rem;

        &__image_wrapper {
            height: $header-bar-logo-height;
            padding-bottom: 0.25rem;
            padding-top: 0.25rem;

            img {
                height: 100%;
            }
        }

        &:hover {
            text-decoration: none;
        }
    }

    &__link {
        &:hover,
        &:link,
        &:active,
        &:visited {
            text-decoration: none;
        }

        padding: 0.5rem;
    }

    &__left {
        display: flex;
        flex: 1;

        .admin-header-bar__link {
            margin-right: 1rem;
        }

    }

    &__right {
        .mat-icon.derifin-icon {
            padding-bottom: 5px;
        }
    }
}
