@config "../../../../../tailwind.config.js";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'dist/chroma-ui/styles/themes';

@import 'fonts';
@import 'variables';
@import 'bootstrap/scss/bootstrap';
@import 'base';
@import 'alerts';
@import 'normalize';
@import 'print';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'ngx-toastr/toastr';
@import "~nouislider/dist/nouislider.min.css";
@import "~@splidejs/splide/dist/css/splide.min.css";
@import '@dericon/ng-select/scss/default.theme';
/** Components **/
@import 'layout';
@import 'header-bar';
@import 'scroll-top-button';
@import 'menu';
@import 'accordion';
@import 'card';
@import 'carousel';
@import 'button';
@import 'slider';
@import 'forms';
@import 'tables';
@import 'dashboard';
@import 'slide-down';
@import 'charts';
@import 'modal';
@import 'page';
@import 'pagination';
@import 'spinner';
@import 'toasts';
@import 'progress';
@import 'tabs';
@import 'widgets';
@import 'auth';
@import 'target-market';
@import 'overlay';
@import 'products';
@import 'product-details';
@import 'watchlists';
@import 'watchlists-group';
@import 'shortlist';
@import 'bwlist';
@import 'entities';
@import 'recommendation';
@import 'filter';
@import 'assets';
@import 'dpc';
@import 'product-designer';
@import 'rfq-issuer';
@import 'advisor-product-baskets';
@import 'products-export';
@import 'risk-manager';
@import 'funds-compare';
@import 'admin-header-bar';
@import 'admin-layout';
@import 'portfolio-manager';
@import 'dragdrop';
@import 'small-layout';
@import 'compact-layout';
@import 'file-list';

@import 'material';
@import 'dialog';
