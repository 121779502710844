.dpc-quote-wizard {
    display: block;
    @include make-row();
    margin-bottom: 3 * $spacer;

    &__steps {
        @include make-col-ready();
        @include make-col(4, 5);
    }

    &__upper-steps,
    &__lower-steps,
    &__product-overview {
        @include make-row();

        margin-right: 0 !important;
        margin-bottom: $grid-gutter-width-base;
    }

    &__product-overview {
        margin-bottom: 0;
    }

    &__step {
        @include make-col-ready();
        @include make-col(1, 4);
    }

    &__underlying-info-box {
        @include make-col-ready();
        @include make-col(3, 4);
    }

    &__summary {
        @include make-col-ready();
        @include make-col(1, 5);
    }

    .card-divider {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.dpc-step {
    display: flex;

    &__card {
        flex: 1;
    }

}

.dpc-summary {

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__list-item {
        margin-bottom: $dpc-summary-item-spacing;
    }

    &__label {
        font-weight: $font-weight-normal;
        font-size: $font-size-sm;
        color: $dericon-medium-grey;
    }

    &__value {
        font-size: $font-size-sm * 1.05;

        ul {
            padding: 0;
        }
    }
}


.dpc-issuer-table-cell {

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__list-item {
        border-bottom: 1px solid $card-divider-color;
    }

}

.dpc-issuer-item {
    display: block;
    padding: 0.5rem 0;

    &__main {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__expand-toggle {

        width: 1rem;
        height: 1rem;
        text-align: center;

        &:after {
            display: block;
            font-size: 1.5rem;
            line-height: 1rem;
            content: '›';
            transform: rotate(90deg);
        }

        &--expanded {
            transform: scaleY(-1);
        }
    }

    &__reason {
        margin-top: 0.5rem;
        font-size: $font-size-sm;
    }

    &--disabled {
        cursor: pointer;
        opacity: 0.6;
    }

}
