/**
 * Styles for custom card variations and layouts
 */

.card-muted {
    @include card-variant($brand-muted, $brand-muted);
}

.card-flat {
    border: none;
}

.card-title {
    font-size: $font-size-base;
    letter-spacing: 1px;
    font-weight: $font-weight-normal;
    text-transform: uppercase;
    margin-bottom: $card-title-margin-y;
}

.card-title-meta {
    position: absolute;
    right: 0;
    top: 0;
}

.card-subtitle {
    font-size: $font-size-base * 0.8;
    letter-spacing: 1px;
    font-weight: $font-weight-normal;
    text-transform: uppercase;
    margin-bottom: $card-title-margin-y * 0.5;
    margin-top: -($card-title-margin-y * 0.5);
}

.card-header {
    border-bottom: none;
}

.card-header-tabs {
    margin: 0;

    .tab-bar-container {
        border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
        width: 100%;
    }
}

.card-headline {
    color: $brand-primary;
    margin-bottom: $card-headline-margin-y;
    font-size: $card-headline-font-size;

    &--with-divider {
        padding-bottom: $card-headline-margin-y;
        border-bottom: 1px solid $card-divider-color;
    }
}

.card-sub-headline {
    color: $brand-primary;
    margin-bottom: $card-headline-margin-y * 0.3;
    font-size: $card-headline-font-size * 0.6;
}

.head-line-number {
    float: right;
    margin-top: 0.5rem;
    font-size: 14px;
    color: $brand-primary;
    font-weight: $font-weight-normal;
}

.card-heading-number {
    display: inline-block;
    float: right;
    font-size: 14px;
    color: $brand-primary;
}

.card-column {
    display: flex;

    & .card {
        flex: 1 1 auto;
    }
}

.split-card {
    display: flex;
    max-width: 100%;

    & > .row {
        flex: 1 1 auto;
    }

    .card-divider {
        width: 1px;
        margin: $card-spacer-x 0;
        margin-left: -1px;
        background: $card-divider-color;

        &.horizontal {
            width: 100%;
            height: 1px;
            margin: 1rem 0;
        }
    }
}

.card.banners {
    padding: 0.625rem 0;
    margin-bottom: 0.625rem;
    min-height: 112px;
    background-color: transparent;
    border: 1px solid $brand-muted;
}
