.advisor-product-baskets {
    &__charts {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__chart {
        opacity: 0.8;
        transition: opacity 0.3s linear;
        cursor: pointer;
        border-right: 1px solid $gray-lighter;
        padding-left: 1rem;
        padding-right: 1rem;

        &.active {
            opacity: 1;
            position: relative;

            &:after {
                content: "";
                display: inline-block;
                position: absolute;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 10px 10px 0 10px;
                border-color: $brand-muted transparent transparent transparent;
                left: 50%;
                transform: translateX(-50%);
                margin-top: -8px;
                z-index: 1;
            }
        }

        &:hover {
            opacity: 1;
        }

        .pie-chart, g, path {
            pointer-events: none !important;
        }

        .card-title {
            text-align: center;
        }

        figure {
            text-align: center;

            > svg {
                overflow: visible;
            }
        }
    }
}
