/** Inverse Form **/

.form-inverse {
    .form-control {
        background: $white !important;
        border-color: $white !important;
    }

    .custom-checkbox,
    .custom-switch {
        .custom-control-indicator {
            border: 1px solid $white !important;
        }

        .custom-control-input:checked ~ .custom-control-indicator {
            background-color: $white !important;
        }
    }
}

.form-group {

    .input-label {
        font-size: $font-size-sm;
        color: $dericon-medium-grey;
        margin-bottom: 0.25rem;
    }

    &.has-danger {
        .input-label {
            color: $brand-danger;
        }
    }

}

/**
 * Styles for forms that appear in cards like the target market forms and the filter forms
 */

.card-form {

    &__section {
        display: block;
        margin-bottom: 2.5rem;

        &--vertical {
            display: flex;
            flex-direction: row;
            align-items: center;

            .form-group-label {
                font-size: 1.0rem;
                padding-right: 0.5rem;
                margin: 0;
            }

            .form-group {
                margin: 0;
            }

            .custom-switch {
                margin-bottom: 0;
                padding-top: 0;
            }
        }
    }
}

/**
 * Styles for custom form controls
 */

.custom-controls-stacked .custom-control {
    padding-top: 0.1rem;
}

.app-entity-table-row,
.switchable-dropdown-input {
    .custom-control {
        display: block;
    }

    .form-group {
        align-items: center;
    }
}

.custom-control-indicator {
    border: $custom-control-border;
}

.custom-control-input {
    &:checked:not(:disabled) ~ .custom-control-indicator {
        border: $custom-control-border-checked;
    }
}

.custom-switch {
    padding-left: $custom-control-gutter + 1rem;

    .custom-control-indicator {
        background-color: $white;
        border-radius: $custom-control-indicator-size*0.5;
        background-image: $custom-switch-icon;
        width: $custom-switch-width;
        background-position: -$custom-switch-width*0.25;
        transition: background-position 250ms;
    }

    .custom-control-input:checked ~ .custom-control-indicator {
        background-color: $white;
        background-position: $custom-switch-width*0.25;
        background-image: $custom-switch-checked-icon;
    }

    .custom-control-input:disabled ~ .custom-control-indicator {
        background-color: $gray-lighter;
        background-image: $custom-switch-icon;
    }
}

.custom-radio {
    .custom-control-indicator {
        background-color: $white;
    }

    .custom-control-input:checked:disabled ~ .custom-control-indicator {
        background-image: $custom-switch-icon;
    }
}

.custom-checkbox {
    .custom-control-indicator {
        background-color: $white;
        border: $custom-control-border;
    }

    .custom-control-input {
        &:indeterminate ~ .custom-control-indicator {
            background-color: transparent;
            border: $custom-control-border-checked;
        }

        &:active ~ .custom-control-indicator {
            background-color: $custom-control-active-indicator-bg;
        }
    }
}


.form-control {
    font-family: inherit;
    background: $white;
    font-size: $font-size-base - 0.0625rem;

    &:focus {
        background: $white !important;
    }
}

.custom-control-sub-control {
    margin-left: 1.5rem;
    margin-top: 0.125rem;
}

.form-group-sub-control {
    margin-left: 1.5rem;
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
}

.form-group-spacing {
    margin-bottom: 0.5rem;
}

.reset-icon {
    display: flex;

    .mat-icon {
        height: 22px;
        width: 22px;
        cursor: pointer;
    }
}

select.form-control,
app-multi-select-dropdown .form-control.ui-select-search {
    height: 26px;
    vertical-align: middle;
}

/** Select Dropdown **/

.ui-select-choices {
    width: 100%;
    height: auto;
    max-height: 300px;
    overflow-x: hidden;
    margin-top: 0;
}

.ui-select-choices-row {
    .dropdown-item {
        padding: 5px 10px 3px 10px;
        font-size: 1rem;
        line-height: 1;

        &.no-matches-text {
            white-space: normal;
        }
    }

    &.active .dropdown-item {
        background: $brand-primary;
        color: $white;
    }
}

.ui-select-toggle {
    cursor: text;
}

.ui-select-choices {
    margin-top: 0.25rem;
}

.ui-select-multiple {
    virtual-scroller {
        > .scrollable-content {
            height: auto;
        }
    }

    &.dropdown-toggle {
        &:after {
            position: absolute;
            top: 50%;
            right: 0.5rem;
            margin-top: -2px;
            pointer-events: none;
        }
    }

    input:placeholder-shown {
        cursor: pointer;
    }

    input:focus {
        cursor: text;
    }

    input {
        height: calc(#{$input-height} + #{$custom-select-border-width});
    }
}

.ui-select-match {

    line-height: 1.5;

    .close {
        margin-left: 5px;
        padding: 0;
        font-size: 1rem;
    }

}

.ui-select-matches {
    padding: 1rem 0.5rem;
}

.ui-selected-match-item {
    &--disabled {
        opacity: 0.5;
    }
}

/** Date Input **/

.date-input {
    display: flex;
    align-items: center;

    &__calendar-icon {
        flex-shrink: 0;
        cursor: pointer;
    }

    .dp-picker-input {
        width: inherit;
    }

    $self: &;
    &--full-width {
        width: 100%;

        #{$self}__input-container {
           width: 90%;
        }
    }

    &__input-container {
        width: 100%;
    }
}

/** Double Dropdown Input Filter **/

.double-dropdown-input, .labelled-radio-button-group-input {

    &__label {
        padding-right: 1rem;
        max-width: 300px;

        @include media-breakpoint-down('lg') {
            max-width: unset;
            flex-basis: 100% !important;
            padding-bottom: 0.3rem;
            padding-left: 0.3rem;

            & + * {
                padding-left: 0.3rem;
            }
        }
    }

    &__sample {
        color: $dericon-medium-grey;
    }

    &__info {
        display: inline-block;
        height: 0.875rem;
        width: 0.875rem;
        line-height: 0.875rem;
        text-align: center;
        color: $body-color;
        border: 1px solid $body-color;
        font-size: 0.625rem;

        &:before {
            content: 'i'
        }
    }

    .app-filter-radio-button-group .mat-button-toggle-group .mat-button-toggle-label-content {
        padding: 0 7px;
        line-height: 23px;
    }
}

.fund-figure-input {
    &__label {
        max-width: 350px;

        @include media-breakpoint-up('lg') {
            max-width: unset;
            flex-basis: 100% !important;
            padding-bottom: 0.3rem;
            padding-left: 0;
        }

        @include media-breakpoint-up('xl') {
            max-width: 125px;
            min-width: 125px;
            padding-left: 0.3125rem;
            word-break: break-word;
        }


        @include media-breakpoint-up('xxl') {
            max-width: 300px;
        }
    }

    @include media-breakpoint-down('lg') {
        > * {
            padding-top: 0.2rem;
            margin-left: 0.3rem;
        }

        > .horizon-selection {
            justify-content: flex-start !important;
        }

        &__label {
            padding-left: 0 !important;
        }
    }
}

.number-input {
    padding: 0;

    input {
        @extend .form-control;
        border: none;
    }

}

textarea.product-comment {
    min-height: 16rem;
}

.add-user-dialog {
    .form-control.ng-touched.ng-invalid:not(form)  {
        border: 2px solid red; /* red */
    }
}


.ng-select {
    .ng-select-container {
        min-height: 34px;
        border-radius: 0;
        border: 1px solid rgba(0, 0, 0, 0.15);

        .ng-value-container {
            padding-left: 12px;

            .ng-input {
                top: 0 !important;
                height: 100% !important;
                z-index: auto !important;

                > input {
                    height: 100%;
                }
            }

            .ng-placeholder {
                font-size: $font-size-base - 0.0625rem;
                padding-top: 0;
                padding-bottom: 0;
                z-index: auto !important;
            }
        }
    }

    &.ng-select-single {
        .ng-select-container {
            height: 34px;

            .ng-value-container {
                .ng-input {
                    left: 2px !important;
                }
            }
        }
    }
    
    &.ng-select-multiple {
        .ng-select-container {
            .ng-value-container {
                padding-left: 9px;

                .ng-placeholder {
                    top: 6px;
                }
            }
        }
    }

    &.ng-select-single.dev-toolbar-select {
        width: 400px;
        font-size: 14px;
        margin-left: 16px;
        margin-right: 16px;

        .ng-select-container {
            height: 36px;
        }
    }

    &.ng-select-focused:not(.ng-select-opened) {
        > .ng-select-container {
            border-color: $brand-primary;
        }
    }

    .ng-dropdown-panel {
        .ng-dropdown-panel-items {
            .ng-option {
                font-size: $font-size-base - 0.0625rem;
                line-height: 1.4285714286;
                padding: 7px 13px;

                &.ng-option-marked,
                &.ng-option-selected {
                    background-color: $brand-primary;
                    color: #FFFFFF;
                }
            }
        }
    }

    .ng-arrow-wrapper {
        background-color: #FFFFFF;

        .ng-arrow {
            border-color: #343a40 transparent transparent;
        }
    }
}

.ng-dropdown-panel.ng-select-bottom {
    border-radius: 0;
}

.input-group-addon {
    font-size: $font-size-base - 0.0625rem;
}

.custom-select {
    width: 100%;
    background-position: right 0.5em center;
}