.watchlists-group-card {
    &__card {
        transition: border-color linear 0.2s;
        border: 1px solid transparent;

        .card-block {
            display: flex;
        }

        &__part--fullheight {
            flex-grow: 1;
        }
    }

    &__last-modified {
        padding-bottom: 0.2rem;
        text-align: center;
        display: inline-block;
        width: 100%;
        font-size: 0.75rem;
    }

    &__product {
        text-align: right;
        text-transform: uppercase;
        font-size: 0.8rem;
        opacity: 0.6;

        display: inline-block;
        position: absolute;
        right: 1rem;
        top:   0.8rem;
    }

    &__product-count {
        font-size: 2.5rem;
        font-weight: bolder;
        opacity: 0.3;
        padding-top: 0;
    }
}
