
@use "sass:math";

@mixin make-print-grid-columns($columns: $grid-columns, $gutters: $grid-gutter-widths, $name: "pr") {
    @media print {
        // Common properties
        %grid-column-print {
            position: relative;
            width: 100%;
            min-height: 1px; // Prevent columns from collapsing when empty

            @include make-gutters($gutters);
        }

        // Allow columns to stretch full width below their breakpoints
        @for $i from 1 through $columns {
            .col-#{$name}-#{$i} {
                @extend %grid-column-print;
            }
        }
        .col-#{$name} {
            @extend %grid-column-print;
        }

        @for $i from 1 through $columns {
            .col-#{$name}-#{$i} {
                display: inline-block !important;
                width: percentage(math.div($i, $columns));
                max-width: percentage(math.div($i, $columns));
            }
        }

        @each $modifier in (pull, push) {
            @for $i from 0 through $columns {
                .#{$modifier}-#{$name}-#{$i} {
                    @include make-col-modifier($modifier, $i, $columns)
                }
            }
        }

        // `$columns - 1` because offsetting by the width of an entire row isn't possible
        @for $i from 0 through ($columns - 1) {
            .offset-#{$name}-#{$i} {
                @include make-col-modifier(offset, $i, $columns);
            }
        }
    }
}

@include make-print-grid-columns();

/** Modifications **/

@media print {

    html {
        font-size: 75%;
    }

    body.overlay-open {
        overflow: visible !important;

        .container {
            display: none !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
        }

        .overlay .container {
            display: block !important;
        }
    }

    .overlay {
        position: static !important;
        height: auto !important;

        &__content {
            position: static !important;
            height: auto !important;
            background: transparent !important;
        }

        .card-block {
            padding: 0 !important;
        }
    }

    .overlay-header {
        padding: 0 !important;
        margin-bottom: 2rem !important;

        &__headline {
            padding-bottom: 0.5rem !important;
        }
    }

    .product-details-widget {
        margin-bottom: 2rem !important;
    }

    .card-title {
        margin-bottom: 1rem !important;
    }

    .product-designer-print-result-page {
        .product-designer__summary__table {
            margin-bottom: 0;
        }

        .header-bar__logo {
            max-height: 3.5rem;
            position: absolute;
            right: 0;
            top: 0;
        }

        .page__header {
            margin-bottom: 1.5rem;
            border-bottom: 0;
        }

        .card-muted, .entity-widget__card--muted {
            background-color: white;
        }

        .card-block {
            padding: 0!important;
        }

        .badge {
            border: 0;
            line-height: normal;
            color: inherit;
        }

        .product-designer-request-parameters-table__column__title, .product-designer__summary__table__column-title {
            width: 30%;
        }


        .product-designer__summary__table-details.table.table-condensed .product-designer__summary__details__item__table th,
        .product-designer__summary__table-details.table.table-condensed .product-designer__summary__details__item__table td {
            padding: .4rem 0 .2rem;
        }

        .product-designer__summary__table-details.table.table-condensed .product-designer__summary__details__item__table th:first-of-type {
            width: 50%;
        }

        .table-condensed.table .product-designer__summary__table__column-title {
            padding-top: 16px;
        }

        .product-designer__summary__table-details.table.table-condensed td {
            padding: .65rem 0;
            background-color: white;
        }

        app-product-designer-request-parameters-table table:first-of-type tr td {
            border: none;
        }

        .product-designer__summary__table-details.table.table-condensed tr:last-child td {
            border: none;
        }

        .product-designer__summary__details__item:not(:last-of-type)::before {
            display: none;
        }

        .product-designer-result__multi-request-table {
            .table__column--bordered, .table__column--frozen {
                border-right: 2px solid $table-border-color;
                border-top: 2px solid $table-border-color;
            }

            .table__column--frozen {
                /*width: 20rem;
                min-width: 20rem;
                max-width: 20rem;*/
                width: auto;
                min-width: auto;
                max-width: none;
                position: relative;
            }

            th.table__column--frozen, th.table__column--bordered {
                border-top: 0!important;
            }

            .product-designer-result__table__issued-box {
                border: 0!important;
            }
        }
    }

    .force-page-break-before {
        page-break-before: always;
    }

    .no-page-break-before {
        page-break-before: avoid;
    }

    .no-page-break-after {
        page-break-after: avoid;
    }
}
