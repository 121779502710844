.slide-down {
    display: block;
    position: relative;

    &__controls {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        z-index: 10;
    }

    &__content {

        &--open {
            margin-bottom: 4rem;
        }
    }

    &__footer {
        border-top: 1px solid $dericon-medium-light-grey;
        height: 4rem;
        display: flex;
        justify-content: flex-end;
        margin: 1.225rem;
        margin-bottom: 0;
    }

    &__footer-link {
        cursor: pointer;
        font-family: $font-family-sans-serif;
        background-color: transparent;
        border: 0;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        margin-right: 1rem;
        text-decoration: none;

        &:hover,
        &:focus {
            color: #000c12;
            text-decoration: none;
        }

        &:after {
            content: '›';
            font-weight: bold;
            padding-left: 0.25rem;
        }

        &:last-child {
            margin-right: 0;
        }

        &:disabled {
            pointer-events: none;
            color: $dericon-medium-grey;
        }
    }

    &__tarket-market__warning-text {
        color: red;
    }
}
