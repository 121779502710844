// Old

.chart-legend {

    &__list {
        list-style: none;
        padding: 0;
    }

    &__item {
        display: flex;
        align-items: center;
    }

    &__indicator {
        height: 0.125rem;
        width: 2rem;
        margin-right: 0.5rem;
    }

    &__label {
        font-size: $font-size-sm;
    }

}


// New

.dynamic-chart {

    &__empty-container {
        display: flex;
        align-items: center;
        justify-content: center;

        &__message {
            display: inline-block;
        }
    }

    &--legend-below {

        .ngx-charts {
            float: none !important;
        }

        .chart-legend {
            display: block !important;

            & > div {
                width: auto !important;
            }

            .legend-wrap {
                width: auto;
            }

            .legend-labels {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
            }

        }

    }

}

.chart-legend {

    .legend-title {
        display: none;
    }

    .legend-labels {
        background-color: transparent !important;
    }

    .legend-label-color {
        border-radius: 0 !important;
        height: 0.125rem !important;
        width: 1.5rem !important;
        margin-right: 0.5rem !important;
    }

    .legend-label-text {
        font-size: $font-size-base * 0.85 !important;
        color: $text-muted !important;
    }

    .active {

        .legend-label-text {
            color: $body-color !important;
        }

        .legend-label-color {
            height: 0.25rem !important;
        }

    }

    ngx-charts-legend-entry > span {
        display: flex;
        align-items: center;
    }

}
