.page {

    &__headline {
        color: $brand-primary;
        padding: $page-headline-padding-y 0;
        font-size: $page-headline-font-size;
        margin: 0;
    }

    &__header {
        margin-bottom: $page-header-sspacing-y;
        border-bottom: 1px solid $dericon-medium-light-grey;
    }

}
