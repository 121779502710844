.scroll-top-button {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    text-align: center;
    outline: none;
    background: $brand-primary;
    color: white;
    padding: 5px 10px;

    transition: opacity 0.2s linear;
    cursor: auto;
    opacity: 0;
    z-index: 1500;

    &__visible {
        opacity: 0.3;
        cursor: pointer;

        &:hover, &:active, &:focus {
            color: white;
        }

        &:hover {
            opacity: 1;
        }
    }
}


.overlay-open {
    .scroll-top-button {
        right: 2rem;
    }
}
