@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('./fonts/Inter-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('./fonts/Inter-Medium.woff2') format('woff2');
}
