$file-list-headline-color: rgb(0, 63, 94);
$file-list-divider-color: #dbdbdb;

.file-list-group {
    h1 {
        color: $file-list-headline-color;
        font-size: 1.75rem;
    }

    h2 {
        color: $file-list-headline-color;
    }

    &__list {
        min-height: 104px;
        word-break: break-all;

        &__headline {
            color: $brand-primary;
            font-size: $font-size-h4;
            white-space: normal;
            text-align: left;
        }

        &__description {
            margin-bottom: $card-headline-margin-y;
            text-align: justify;
            word-break: break-word;
        }
        &__divider {
            padding-top: 1rem;
            margin-bottom: 1rem;
            border-bottom: 1px solid $file-list-divider-color;
        }

        &__entry {

            &__title {
                color: $dericon-dark-grey;
                font-weight: bold;
                font-size: $font-size-base;
                white-space: normal;
                text-align: left;
            }

            &__description {
                padding-top: 1rem;
                word-break: break-word;
            }

            &__files {
                padding-top: 1rem;
                padding-left: 1rem;
                margin-bottom: 0;
            }
        }
    }
}
