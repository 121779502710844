.product-list-page,
.trade-list-page {
    &__spinner {
        text-align: center;
    }
}

.filter {
    display: block;
    margin-bottom: $grid-gutter-width-base;

    @for $i from 1 through 10 {
        &__col-#{$i} {
            flex: $i;
        }
    }

    &__vertical {
        .split-card > .row {
            flex-direction: column;
            justify-items: flex-start;

            > .card-divider {
                display: none;
            }

            > section {
                flex-basis: auto;
            }

            .row {
                flex-wrap: unset;
                flex-direction: row;
            }
        }
    }
}

.filter-control {

    &--untitled {
        margin-bottom: 0;
    }

}

.specific-filter {
    display: block;
    overflow: hidden;
    margin-bottom: 0;
}

.product-list-header,
.trade-list-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 1.5rem;
    border-top: 1px solid $dericon-medium-light-grey;
    padding-bottom: 2rem;
    background: $white;

    &--bottom {
        padding-bottom: 0;
    }

    &__secondary {
        display: flex;
        align-items: center;
        height: 2.625rem;
    }

    &__count {
        margin-left: 1.5rem;
        font-size: $font-size-sm;
        color: $brand-primary;
    }
}

.product-list {
    display: block;
    //margin-top: $grid-gutter-width-base;

    &__session-error {
        position: absolute;
        width: 100%;
        bottom: 2rem;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;

        .alert {
            position: fixed;
            text-align: center;
        }
    }
}

/** Entity Selection Overlay **/

.product-selection-overlay {

    .tab-bar-container.nav-tabs {
        border-bottom: 0;
    }

    &__actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 0.3rem;

        .actions-container {
            margin-bottom: $overlay-actions-spacing-y;
        }
    }


    &__switcher {

        &__label {
            color: $dericon-dark-grey;
        }

        &__active {
            border: none;
            background: transparent;

            color: $dericon-blue;

            &:hover {
                color: $dericon-blue;
                background: transparent;
            }
        }

        &__inactive {
            border: none;
            background: transparent;

            color: $dericon-medium-grey;

            &:hover {
                color: $dericon-medium-grey;
                background: transparent;
            }
        }
    }

    &__form {
        &__actions {
            border-top: 1px solid #dbdbdb;
            margin-top: 1rem;
            padding-top: 1rem;
            text-align: right;
            display: flex;
            justify-content: space-between;
        }
    }
}

/** Entity Selection Indicator **/

.product-selection-indicator {
    display: flex;
    align-items: center;
    color: $dericon-medium-grey;
    cursor: pointer;
    outline: none;
    z-index: 1;
    position: relative;

    &__label {
        margin-right: 1rem;

        &--has-selection {
            color: $brand-primary;
        }
    }

    &__checkbox {
        width: 1.75rem;
        height: 1.75rem;
        border: 1px solid;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &--has-selection {
            color: $brand-primary;
        }
    }

    &__checkmark {
        display: flex;
        align-items: center;
    }

    &__selection-count {
        display: block;
        position: absolute;
        left: 1rem;
        bottom: 1rem;
        background: $brand-primary;
        color: $white;
        width: 1.5rem;
        height: 1.5rem;
        line-height: 1.7rem;
        text-align: center;
        border-radius: 50%;
        font-weight: $font-weight-bold;
        font-size: $font-size-sm;
    }

    & + .product-list-header__count {
        padding-top: 1rem;
    }
}

.trade-list-page {
    &__entity-highlights {
        background-color: #ffffff;
    }
}
