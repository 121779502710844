.products-export {
    &__input-panel {
        @include make-col-ready($grid-gutter-widths-large);
        @include make-col(3, 10);

        background: $dericon-light-grey;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    &__execute-panel {
        @include make-col-ready($grid-gutter-widths-large);
        @include make-col(3, 10);

        padding-top: 1rem;
        padding-bottom: 1rem;
        background: $dericon-light-grey;
        margin-left: 0.5rem;

        display: flex;
        flex-direction: column;

        &__buttons {
            flex: 1;
            align-content: flex-end;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
        }
    }

    &__result-panel {
        @include make-col-ready($grid-gutter-widths-large);
        @include make-col(3, 10);

        padding-top: 1rem;
        padding-bottom: 1rem;
        border: 1px solid $dericon-medium-light-grey;
        margin-left: 0.5rem;

        display: flex;
        flex-direction: column;

    }

    &__loading-container {
        display: flex;
        flex: 3;
        align-items: center;
    }
}
