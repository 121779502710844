.cdk-drag:not(.entity-details-table-cell--loading) {
    cursor: move;
}

tr.cdk-drop-list-dragging th:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.funds-comparison-table {
    &__scroll {
        position:relative;
        overflow:hidden;
        background: white;
        padding: 1rem;
    }

    &__wrap {
        width:100%;
        overflow:auto;
    }

    &__header-cell {
        strong {
            display: block;
        }
    }

    &__table {
        border-collapse: separate;
        border-spacing: 0;

        margin-bottom: 0;

        tr:first-child {
            td, th {
                border-top: none;
            }
        }

        th,
        &__line-label {
            border-top: $table-border-width solid $table-border-color;
            padding-top: $table-cell-padding + 0.125rem;
            padding-right: 3rem;
            white-space: normal;

            font-weight: normal;
            font-size: 0.875rem;
            color: $dericon-medium-grey;
        }

        td {
            width: auto;
        }

        td, th {
            min-width: 200px;
            border-right: 1px solid $table-border-color;

            &:last-of-type {
                border-right: 0;
            }
        }

        tr:hover td {
            background-color: #e5e5e5 !important;
        }
    }

    &__cell--sticky{
        position: sticky;
        left: 0;
    }

    &__header-cell {
        &.cdk-drag-preview {
            padding-top: 0.875rem;
            padding-right: 3rem;
            padding-left: 1rem;
            background: rgba($brand-muted, 0.7);
        }

        &__placeholder {
            background: #ccc;
            border: dotted 3px #999;
            height: 100%;
            transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
        }
    }
}

.funds-comparison-chart {
    &__container {
        background: white;
        padding-top: 1.5rem;
        padding-bottom: 1rem;
    }

    &__spinner {
        width: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
