.overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 100%;
    transition: background 250ms ease-in 150ms, top 0ms 500ms;
    z-index: 1499;

    $element: &;

    &--active {
        top: 0;
        background: rgba(0, 0, 0, 0.2);
        transition: background 250ms ease-out;

        #{$element}__content {
            transform: translateY(0);
            transition: transform 250ms ease-out 150ms;
        }
    }

    &__content {
        position: absolute;
        width: 100%;
        height: 100%;
        background: $brand-muted;
        overflow: auto;
        transition: transform 250ms ease-in;
        transform: translateY(100%);

        &.product-details, &.watchlist-details {
            .container {
                max-width: 1690px;

                .product-selection-overlay__actions .actions-container {
                    margin-bottom: 0.5rem;

                    .entity-actions {
                        height: 2rem;
                    }
                }

                .overlay-header__container {
                    height: 5rem;
                    border-bottom: 0;
                }

                .product-details-tabs .tab-bar-container {
                    margin-bottom: 0.5rem;
                }

                .card-header-tabs {
                    margin-top: 0.5rem;
                }

                .navbar.header-bar {
                    padding: 0;
                }
            }
        }
    }

    .highlighted-box {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 1rem;
        background: #EEEEEE;
        border: 1px solid #DDDDDD;
        width: 500px;
    }

}

.overlay-header {
    padding: $overlay-header-spacing-y 0;

    &__container {
        height: $overlay-header-height;
        border-bottom: 1px solid $dericon-medium-light-grey;
    }

    &__text {
        flex-direction: column !important;
    }

    &__headline {
        color: $brand-primary;
        font-size: $overlay-headline-font-size;
        margin-bottom: 0;
    }

    &__sub-headline {
        font-size: $overlay-headline-font-size * 0.6;
        // text-transform: uppercase;
        margin-bottom: 0;
    }
}


.overlay-error {

    margin-top: 6rem;
    text-align: center;

    &__heading {
        margin-bottom: 1.5rem;
    }

    &__description {
        margin-bottom: 1.5rem;
    }

    &__centered {
        display: flex;
        justify-content: center;
    }

}

body.overlay-open {
    overflow: hidden;
}
