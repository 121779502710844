.widget-container {
    @include make-row();
    margin-bottom: -($grid-gutter-width-base);

    &--responsive {
        .entity-widget {
            &--col-1 {
                @include make-col(2, 4);

                @include media-breakpoint-up('xxl') {
                    @include make-col(1, 5);
                }
            }

            &--col-2 {
                @include make-col(2, 4);

                @include media-breakpoint-up('xxl') {
                    @include make-col(2, 5);
                }
            }

            &--col-3 {
                @include make-col(3, 4);

                @include media-breakpoint-up('xxl') {
                    @include make-col(3, 5);
                }
            }

            &--col-4 {
                @include make-col(4, 4);

                @include media-breakpoint-up('xxl') {
                    @include make-col(4, 5);
                }
            }

            &--col-5 {
                @include make-col(4, 4);

                @include media-breakpoint-up('xxl') {
                    @include make-col(5, 5);
                }
            }
        }

        .entity-widget__card--xx_bigger {
            &.entity-widget {
                /*&--col-2 {
                    @include make-col(4, 4);

                    @include media-breakpoint-up('xxl') {
                        @include make-col(2, 5);
                    }
*/
                .card-title {
                    margin-bottom: 3rem;
                }

                .dynamic-chart {
                    > * {
                        max-height: 15vmax;
                        //min-height: 400px;
                    }
                }

                .ngx-charts-outer {
                    display: flex;
                    align-content: center;
                    align-items: center;
                    flex-direction: column;
                }

                //}
            }
        }
    }

    &--compact {
        .entity-widget__card {
            .card-block {
                padding: 0.7rem;
            }
        }
    }

    @for $x from 1 through 12 {
        &--cols-#{$x} {
            .entity-widget {
                @for $y from 1 through $x {
                    &--col-#{$y} {
                        @include make-col($y, $x);
                    }
                }
            }
        }
    }

    &--inverse {

        .card {
            border: none;
        }

    }

}

.entity-widget {
    @include make-col-ready();
    margin-bottom: $grid-gutter-width-base;
    page-break-inside: avoid;

    .card-block {
        display: flex;
        flex-direction: column;

        &__container {
            flex: 1 1 auto;
            display: flex;
            min-height: 0;

            > * {
                flex: 1 1 auto;
                width: 100%;
            }
        }
    }

    &__card {
        position: relative;
        overflow: hidden;

        ul {
            padding-left: 15px;
        }

        &--responsive-table {
            .markdown {
                table {
                    display: block;

                    > tbody {
                        display: block;

                        > tr {
                            display: flex;
                            flex-wrap: wrap;

                            > td {
                                display: block;
                                padding-right: 1rem;
                                padding-bottom: 1rem;
                            }
                        }
                    }
                }
            }
        }

        &--compact-form {
            .card-form__section {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 0.5rem !important;

                .card-headline {
                    padding-right: 0.5rem;
                    margin-bottom: 0 !important;
                    font-size: $font-size-base !important;
                    min-width: 9rem;
                    max-width: 9rem;
                }

                .filter-control {
                    .form-group {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &--flat {
            background: transparent;

            .card-block {
                padding-left: 0;
                padding-right: 0;
                padding-top: 0;
            }
        }

        &--muted {
            @extend .card-muted
        }

        &--no-title {
            .card-title {
                display: none !important;
            }
        }

        &--no-type-filter { // @NOTE(alex): Add an option in the widget for that later instead of a style hint
            .entity-widget-focuslist-change__filter {
                .app-filter-radio-button-group {
                    display: none;
                }
            }
        }

        &--no-type-column {
            .entity-widget-focuslist-change__container {
                table {
                    thead tr th:last-of-type {
                        display: none;
                    }

                    tbody tr td:last-of-type:not(.text-center) {
                        display: none;
                    }

                }
            }
        }

        &--drag-over {
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: fade_out($brand-primary, 0.9);
                border: 0.125rem dashed $brand-primary;
            }
        }

        &--market-indicator.card {
            > .card-block {
                padding: 0.1rem;
            }
        }

        &--market-overview {
            .card-block {
                padding-bottom: 1.225rem !important;
            }
        }

        &--graph {
            .card-block > app-entity-widget-graph {
                flex: 1 1 100%;

                display: flex;
                flex-direction: row;
                align-content: center;
                align-items: center;
            }
        }

        &--mercury-top-posts {
            > .card-block {
                padding-bottom: 1.225rem !important;
            }
        }
    }
}

app-entity-widget-mercury {
    display: flex;
    flex-direction: column;
    min-height: 0;

    .entity-widget-mercury {
        &__list-container {
            flex: 0 1 auto;
            overflow: auto;
            max-height: 0;
            min-height: 250px;
        }

        &__list {
            list-style: none;
            margin: 1rem 0 0 0;
            padding-left: 0 !important;
        }

        &__list__item {
            list-style: none;

            display: flex;
            overflow: hidden;
            position: relative;
            z-index: 1;
            margin-left: 0;
            margin-right: 0;
            padding: 0.4rem 0;
            flex-wrap: nowrap;
            border-bottom: 1px solid $card-border-color;

            &:last-child {
                border-bottom: 0;
            }

            &__icon {
                justify-content: center;
                display: flex;
                flex-grow: 0;
                position: relative;
                padding-right: 0.8rem;
                padding-left: 0.8rem;
                align-items: center;
            }

            &__content {
                flex-grow: 1;
                margin-right: 10px;
            }

            &__title {
                font-size: $font-size-h6;
                font-weight: bold;
                padding-top: 0.2rem;
            }

            &__meta-top {
                .mat-icon.material-icon {
                    width: 17px;
                    height: 17px;
                }
            }

            &__meta {
                padding-top: 0.2rem;
            }

            &__text {
                white-space: normal;
                word-break: break-all;
            }
        }
    }
}

.entity-widget-graph {
    display: flex;

    &__y-axis-label {
        white-space: nowrap;
        font-size: $font-size-sm;
        margin-right: 1rem;
    }

    &__x-axis-label {
        text-align: right;
        font-size: $font-size-sm;
        margin-top: 1rem;
    }

    &__chart-container {
        flex: 1;
    }

    &__chart {
        width: 100% !important;
    }

    &__legend {
        margin-left: 1rem;
    }

}

.entity-widget-product-promotion {

    &__content {
        margin-bottom: 1rem;
    }

    &__action {
        border-top: 1px solid $table-border-color;
        text-align: right;

        .entity-actions {
            justify-content: flex-end;
        }
    }
}

.markdown {
    h1 {
        color: $brand-primary;
        margin-bottom: $card-headline-margin-y;
        font-size: $card-headline-font-size;
    }

    img {
        max-width: 100%;
    }
}

.image-news-widget {

    &__image {
        width: 100%;
        height: 120%;
        overflow: hidden;
        position: relative;
        background-repeat: no-repeat;
        background-position: center 30%;
        background-size: cover;

        &--LEFT {
            margin: -2rem;
            width: 100%;
            height: auto !important;
            bottom: 0;
            top: 0;
            position: absolute;
        }

        &--RIGHT {
            margin-top: -2rem;
            height: auto !important;
            right: -2rem;
            bottom: -1rem;
            top: 0;
            position: absolute !important;
        }

        &--TOP {
            margin: -2rem -2.5rem 1.25rem -2rem;
            width: 125%;
            min-height: 150px;
        }

        &--BOTTOM {
            margin: 1.25rem -2.5rem -2rem -2rem;
            width: 125%;

            min-height: 150px;
        }
    }

    &__markdown {
        h1 {
            color: $brand-primary;
            margin-bottom: $card-headline-margin-y;
            font-size: $card-headline-font-size;
        }
    }

}

.image-widget {
    &__image {
        width: 100%;
    }
}

.product-document-upload-widget {
    &__container {
        display: flex;
        flex-direction: column;

        align-items: stretch;
    }

    &__date {
        margin-top: 1rem;
        border-top: 1px solid $dericon-medium-light-grey;
        padding-top: 1rem;
        margin-bottom: 1rem;

        app-date-input {
            width: 100%;

            .date-input__input-container {
                flex: 1;
            }
        }
    }

    &__header,
    &__upload-new-document {
        margin-bottom: 1rem;

        button {
            width: 100%;
        }
    }

    &__select-file {
        flex:1;

        input {
            display: none;
        }

        button {
            width: 100%;
            overflow: hidden;
        }
    }

    &__open-file {
        flex: 1;
        margin-left: 0.5rem;

        button {
            width: 100%;
        }
    }


    &__submit {
        button {
            width: 100%;
        }
    }

    &__remove-file {
        flex:1;
        margin-right: 0.5rem;

        button {
            width: 100%;
        }
    }

    &__button-wrapper {
        display: flex;
        overflow: hidden;
    }

}

.entity-widget-table-actions {
    margin-bottom: $overlay-table-widget-spacing-y;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.entity-widget-product-promotion {
    .entity-table-header__label {
        min-height: auto;
    }
}

.entity-widget__card--muted {
    .entity-table thead th {
        background: $brand-muted;
    }
}

.focus-list-widget {
    min-height: 200px;
    position: relative;
    flex-grow: 1;
    display: flex;
    margin-left: -2.2rem;
    margin-right: -2.2rem;
    margin-top: -2.2rem;
    margin-bottom: -2rem;

    > .row {
        flex-grow: 1;
        flex-wrap: nowrap;
        margin-right: 0.1rem;
        margin-left: 0.1rem;
    }

    .col {
        padding-right: 0.1rem;
        padding-left: 0.1rem;

        &:hover {
            cursor: pointer;
        }
    }

    &__background {
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 0;
        position: relative;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        transition: filter linear 0.2s, background linear 0.2s;
    }

    &__overlay {
        height: 100%;
        width: 100%;
        bottom: 0;
        position: absolute;
        z-index: 1;

        background: rgb(255, 255, 255);
        background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 40%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0.8) 100%);
        background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 40%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0.8) 100%);
        background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 40%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0.8) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#000000", GradientType=1);

    }

    &__image {
        min-width: 100%;
        position: absolute;
    }

    &__text {
        position: absolute;
        bottom: 1.25rem;
        width: 93%;
        text-align: center;
        z-index: 2;
        color: white;
        pointer-events: none;
    }

    & ~ .card-title {
        position: absolute;
    }
}

.entity-widget-focuslist-change {
    $self: &;

    &__container {
        max-height: 550px;
        min-height: 350px;
        height: 100%;
        overflow: auto;
        margin-bottom: 1rem;
        position: relative;

        table thead th {
            background: $brand-muted;
        }
    }

    &__tabs {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: 2rem;
        padding-right: 0;
    }

    &__filter {
        &__column {
            border-bottom: 1px solid #ddd;
        }

        &__type {
            text-align: left;
            padding-bottom: 0.5rem;
        }

        &__search {
            padding-top: 0.2rem;
            text-align: right;
        }
    }
}

.entity-widget-equivalence-check {

    &__list {
        list-style: none;
        padding: 0 !important;
        margin-bottom: 0;

        > li {
            padding: 0.5rem;

            &:not(:last-child) {
                border-bottom: 1px solid $card-border-color;
            }
        }
    }
}

.entity-widget-top-trades,
.entity-widget-latest-issued-products {
    .app-filter-radio-button-group {
        .mat-button-toggle-group {
            flex-wrap: wrap;
            background: white;
        }
    }

    .table {
        thead {
            th {
                text-align: center;
            }
        }

        td, th {
            &:not(:last-child) {
                border-right: 1px solid #eceeef;
            }
        }
    }

    &__list {
        max-height: 35rem;
        overflow: auto;
        list-style: none;
        margin: 0.5rem -1rem 1rem 0;
        padding: 0 !important;

        display: flex;
        flex-wrap: wrap;
        align-content: stretch;

        > li {
            width: 50%;
            padding: 0.25rem 0.25rem;
            flex: 0 0 48%;
            display: flex;
            flex-direction: column;

            > * {
                height: 100%;
            }

            @include media-breakpoint-down('lg') {
                width: 100%;
            }
        }

        &__item {
            background: white;
            border: 1px solid transparent;
            padding: 0.5rem;
            display: flex;
            flex: 1 1 0%;
            height: 100%;

            &__position {
                flex: 0 1 0;
                font-size: 3rem;
                font-weight: bold;
                opacity: 0.3;
                padding: 0 1rem 0 0.5rem;
            }

            &__content {
                flex: 1 1 100%;
                display: flex;
                flex-direction: column;
            }

            &__name {
                font-size: 1rem;
                display: block;
            }

            &__meta {
                display: block;
                margin-top: auto;
            }

            &__volume {
                display: block;
                font-weight: bold;
                padding-top: 3px;
            }

            &__issue-date {
                display: block;
                font-weight: bold;
                padding-top: 3px;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.entity-widget__card {
    $self: &;

    &--focus-list {
        .card-title {
            margin-bottom: 2.2rem;
        }

        &#{$self}--compact {
            .focus-list-widget {
                min-height: 156px !important;
            }
        }

        &#{$self}--text-middle {
            .focus-list-widget__text {
                top: 0.25rem;
                bottom: 0;
                left: 0.1rem;
                right: 0.1rem;
                width: auto;
                height: auto;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    &--full-heig,
    &--full-height {
        .image-news-widget {
            display: flex;
            flex: 1 1 100%;
        }
    }

    &--fixed {
        &-medium {
            max-height: 20rem;
            min-height: 20rem;

            .card-block {
                max-height: 19rem;
                min-height: 19rem;
            }
        }

        &-large {
            max-height: 35rem;
            min-height: 35rem;
        }

        &-small {
            max-height: 10rem;
            min-height: 10rem;
        }
    }


    &--combined {
        border: none !important;
        background-color: transparent !important;

        > .card-block {
            padding: 0 !important;
        }

        .widget-container {
            flex-direction: row;

            &--responsive {
                .entity-widget {
                    @for $y from 1 through 12 {
                        &--col-#{$y} {
                            flex: 0 0 100%;
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }

    &--bg-0 {
        .image-news-widget__image {
            background-position: center 0;
        }
    }

    &--bg-10 {
        .image-news-widget__image {
            background-position: center 10%;
        }
    }

    &--bg-20 {
        .image-news-widget__image {
            background-position: center 20%;
        }
    }

    &--bg-40 {
        .image-news-widget__image {
            background-position: center 40%;
        }
    }

    &--bg-50 {
        .image-news-widget__image {
            background-position: center 50%;
        }
    }

    &--bg-60 {
        .image-news-widget__image {
            background-position: center 60%;
        }
    }

    &--bg-70 {
        .image-news-widget__image {
            background-position: center 70%;
        }
    }

    &--grayscale {
        .focus-list-widget {
            &__background {
                filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
                filter: gray; /* IE6-9 */
                -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
            }
        }
    }

    &--no-fade {
        .focus-list-widget {
            &__overlay {
                background: rgb(255, 255, 255);
                background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 0) 70%, rgba(0, 0, 0, 0.8) 100%);
                background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 0) 70%, rgba(0, 0, 0, 0.8) 100%);
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 70%, rgba(0, 0, 0, 0.8) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#000000", GradientType=1);
            }
        }
    }

    .widget-info-text {
        color: $dericon-medium-grey;
        text-align: center;
        margin: 0.8rem 0 0 0;
    }

    &--form {
        &.card {
            .card-block {
                padding: 0.7rem;
                padding-bottom: 0.1rem;

                .card-title {
                    margin-bottom: 1rem;
                }

                .card-form__section {
                    margin-bottom: 0.8rem;

                    .card-headline {
                        font-size: 1rem;
                        margin-bottom: 0.4rem;
                    }

                    .filter-control {
                        .ui-select-matches {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}

app-entity-widget-top-underlyings {
    .header-row {
        .app-filter-radio-button-group {
            display: flex;

            .working-days {
                padding: 0.5rem;
                display: flex;
                align-items: center;

                .working-days-label {
                    padding-right: 0.5rem;
                }
            }

            .provisioned {
                padding: 0.5rem;
                display: flex;
                align-items: center;

                .provisioned-label {
                    padding-right: 0.5rem;
                }
            }
        }
    }

    .table-row {
        padding-bottom: 0.9rem;

        app-entity-table {
            content-loader {
                height: 8px !important;
                margin-bottom: 4px;
                overflow: hidden;
            }

            .entity-table-header {
                &__label {
                    min-height: auto;
                }

                &:nth-child(2) {
                    white-space: nowrap;
                }
            }

            .entity-table-cell {
                &:nth-child(2) {
                    text-align: right;
                }
            }
        }
    }
}

.product-tmc-widget {
    &__actions {
        position: absolute;
        right: 0;
        top: 0;
    }

    &__popover {
        min-width: 350px;
        max-width: 500px;

        border: 1px solid rgba(0, 0, 0, 0.2);
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;


        background-color: #fff;
        line-height: 1.25;
        font-size: $font-size-sm;
        word-wrap: break-word;
        background-clip: padding-box;
    }
}

.product-data-source-widget {
    &__item {
        &__name {
            font-size: $font-size-sm;
            margin: 0;
            color: $text-muted;
        }
    }
}

.sponsoring-widget {
    padding-bottom: 1.225rem;

    &--opened {
        padding-left: 1rem;
        width: calc(100% - 240px);
    }

    &__details {
        app-icon {
            position: relative;
            top: 5px;
        }

        .mat-icon.material-icon {
            width: 20px;
            height: 20px;
        }

        &__close {
            text-align: right;

            mat-icon.mat-icon.derifin-icon {
                width: 10px;
                height: 10px;
            }

            margin-top: 2px;
            margin-bottom: 19px;
        }


        &__doc {
            margin-left: 0.3rem;
        }

        &__title {
            color: $brand-primary;
            padding-top: 1rem;
        }

        &__logo {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            background-color: white;
            width: 100%;
            height: 100px;
        }
    }

    &__group {
        $group: &;

        &__container {
            .splide__slide.selected #{$group}__item__logo {
                border: 2px solid $brand-primary;
            }
        }

        &__title {
            color: $brand-primary;
        }

        &__item {
            $self: &;
            display: flex;
            flex-direction: column;
            align-items: center;

            &__logo {
                border: 2px solid transparent;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
                background-color: white;
                width: 120px;
                height: 80px;

                &:hover {
                    cursor: pointer;
                    border: 2px solid $table-border-color;
                }
            }

            &__name {
                font-size: $font-size-base;
                padding: 0.5rem 2px;
                text-align: center;
            }

            &.selected {
                #{$self}__logo {
                    border: 2px solid $brand-primary;
                }
            }
        }
    }
}

.entity-widget-mischprodukte {
    &__graph {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        figure {
            text-align: center;

            svg {
                overflow: visible;
            }
        }
    }

    &__summary {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15pt;
    }
}

.contact-partner {

    app-icon {
        position: relative;
        top: 5px;
    }

    .mat-icon.material-icon {
        width: 20px;
        height: 20px;
    }

    &__avatar {
        &__container {
            display: flex;
            justify-content: center;
            padding: 20px 0;
            flex-direction: row;
        }

        width: 100px;
        height: 100px;
        background-size: cover;
        background-position: top center;
        border-radius: 50%;
    }

    &__detail {
        display: flex;
        align-items: baseline;

        :nth-child(2) {
            padding-left: 0.5rem;
            word-break: break-all;
        }
    }
}
