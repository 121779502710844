/**
 * Styles for header-bar
 */

/** HeaderBarComponent **/

.header-bar {

    color: $brand-primary;

    .navbar-brand {
        margin-right: 6rem;
        position: relative;
        bottom: 0.25rem;
        height: $header-bar-logo-height;
    }

    &__logo {
        height: 100%;
    }

    &__link {
        display: flex;
        align-items: center;
        margin-right: 1rem;
    }

    &__dropdown-link {
        display: flex;
        align-items: center;

        span {
            margin-right: 0.5rem;
        }

        &:after {
            display: block;
            font-size: 1.5rem;
            line-height: 1rem;
            content: '›';
            transform: rotate(90deg);
        }

        &--active {
            &:after {
                transform: rotate(-90deg);
            }
        }

    }

    &__logout-button, &__login-button {
        display: flex;
        align-items: center;
    }

    &__login-button{
        position: relative;
        z-index: 100;

        /*&::before {
            content: "";
            position: absolute;
            top: -5px;
            left: -5px;
            bottom: -5px;
            right: -5px;
            opacity: 0.5;
            background: #000000;
            //background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
        }*/
    }

    &__profile-button,
    &__customer-selection-button {
        display: flex;
        align-items: center;
    }

    &__logout-button-label, &__login-button-label {
        margin-right: 0.5rem;
        z-index: 101;
    }

    &__profile-button-label,
    &__customer-selection-button-label {
        margin-right: 0.5rem;
    }

    &__hamburger-menu-button-label {
        margin-right: 1.0rem;
    }

    &__login-button-icon,
    &__logout-button-icon {
        display: flex;
        align-items: center;
    }

    &__login-button-icon > .mat-icon,
    &__logout-button-icon > .mat-icon {
        height: 25px;
        width: 35px;
        z-index: 101;
    }

    &__menu-button {
        margin: 0;
    }

    .icon-link {
        mat-icon {
            display: inline-block;
            padding-top: 5px;
        }
    }

    &__divider {
        display: inline-block;
        align-self: stretch;
        min-height: 1em;
        width: 1px;
        background: $brand-primary;
        margin-left: 1.5rem;
        margin-right: 1rem;
    }

    .current-page-indicator {
        margin-right: 1rem;
        margin-left: 1rem;
    }

}


/** CurrentPageIndicatorComponent **/

.current-page-indicator {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__label {
        line-height: 1;
    }
}

.admin-layout {
    &__header-bar__container {
        background: $brand-primary;

        a {
            color: $white;
        }
    }
}
