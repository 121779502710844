/**
 * Style customizations for buttons
 */

.btn {
    font-size: $font-size-base - 0.0625rem;
}

button.btn {
    cursor: pointer;
}

.btn-link {
    font-family: $font-family-sans-serif;
}

/** Icon Button **/

.icon-button {
    display: inline-block;
    height: $icon-button-size;
    width: $icon-button-size;

    mat-icon.material-icon {
        display: block;
        height: $icon-button-size - 0.1rem;
        width: $icon-button-size - 0.1rem;
    }

    color: $brand-primary;
    border: 0.0625rem solid $brand-primary;
    background: transparent;
    cursor: pointer;
    transition: all 300ms;
    padding: 0;
    margin: 0;

    &:hover,
    &:focus,
    &:active {
        background: $brand-primary;
        color: $white;
    }

    &--inverse {
        color: $white;
        border-color: $white;

        &:hover,
        &:focus,
        &:active {
            background: $white;
            color: $brand-primary;
        }
    }

    &__icon,
    &__icon > .mat-icon {
        width: $icon-button-size - 0.125rem;
        height: $icon-button-size - 0.125rem;
    }

    &__delete-icon > .mat-icon {
        padding: 5px;

        &:hover {
            background-color: rgba($color: #000000, $alpha: 0.1);
        }
    }
}

.icon-label-button {
    display: flex;
    align-items: center;

    &:hover,
    &:focus,
    &:active {
        .icon-button {
            background: $brand-primary;
            color: $white;
        }
    }

    .icon-button {
        vertical-align: middle;
        margin-right: 0.5rem;
    }

    .mat-icon {
        margin-right: 0.5rem;
    }
}
