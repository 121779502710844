$pd-gutter-widths: (
    xs: 3rem
) !default;

.product-designer {
    $a: &;

    display: block;
    @include make-row();
    margin-bottom: 3 * $spacer;
    margin-top: 2rem;

    .card-divider {
        margin-top: 0;
        margin-bottom: 0;
    }

    &__groups {
        @include make-col-ready($pd-gutter-widths);
        @include make-col(3, 5);

        padding-right: 2rem;

        app-product-designer-group {
            #{$a}__group {
                margin-top: 2rem;

                > h3 {
                    font-size: $font-size-h6;
                    text-transform: uppercase;
                }
            }

            &:first-child {
                #{$a}__group {
                    margin-top: 1rem;
                }
            }
        }

        &.step-by-step-mode {
            padding-bottom: 5rem;
        }

        &.full-width {
            @include make-col(5, 5);
        }
    }

    &__group-components {
        @include make-row();
    }

    &__group-actions {
        margin-bottom: 1rem;

        &__buttons {
            .entity-actions {
                margin-left: -1rem;
                flex-wrap: wrap;
                height: auto;
            }

            .entity-actions__action {
                $action: &;
                margin-bottom: 4px;
                border-right: 1px solid $dericon-medium-light-grey;
                border-left: 0;

                &:first-child {
                    padding-left: 1rem;
                    border-right: 1px solid $dericon-medium-light-grey;
                }

                &:last-child {
                    border-right: 0;
                }

                &-link {
                    text-decoration: none;
                    background: $dericon-light-grey;
                    padding: 5px;
                    border: 1px solid transparent;
                    transition: border 0.2s linear;

                    &:hover {
                        border: 1px solid $dericon-medium-grey;
                    }
                }
            }
        }
    }

    &__group-component {
        position: relative;

        .product-designer__group-components > &__hr {
            display: none;

            &:first-of-type:not(:first-child) {
                &:before {
                    content: "OPTIONAL";
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translateY(-45%) translateX(-50%);
                    background: white;
                    padding: 5px;
                }

                opacity: 0.6;
                position: relative;
                display: block;
                flex-basis: 100%;
                height: 1px;
                background: $dericon-medium-light-grey;
                margin-bottom: 25px;
                border: 0;
            }
        }

        &__decompose-container {
            position: relative;

            &.open-container {
                padding-top: 2.5rem;
                border-top: 2px solid $custom-entity-table-row-selected-bg;
            }

            &.close-container {
                margin-bottom: 1rem;
                border-bottom: 2px solid $custom-entity-table-row-selected-bg;
            }
        }

        &__confirm-overlay {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background: rgba(0, 0, 0, 0.8);
            z-index: 3;
            padding: 2rem;

            h1, h3 {
                color: white;
            }

            h3 {
                margin-top: 2rem;
                margin-bottom: 2rem;
            }
        }

        &__buttons {
            position: absolute;
            right: 5px;
            top: 5px;
            z-index: 2;

            > div {
                display: inline-block;

                &:not(:last-child) {
                    margin-right: 5px;
                }
            }

            .btn {
                line-height: 0.6;
                padding: 5px;
            }
        }

        &__delete-button {


        }

        &__blow-up-button {
            z-index: 2;

            button.btn {
                line-height: 0.6;
                padding: 0.5rem 0.5rem 0.5rem 1.8rem;
                text-transform: uppercase;
                font-size: 0.8rem;
                position: relative;

                app-icon {
                    position: absolute;
                    top: 0.15rem;
                    left: 0.25rem;
                }

                .mat-icon {
                    height: 19px;
                    width: 19px;
                }
            }
        }

        .card {
            .card-block {
                padding: 1rem;
                padding-bottom: 0.1rem;

                .card-title {
                    margin-bottom: 1rem;
                }

                .card-form__section {
                    margin-bottom: 0.8rem;

                    .card-headline {
                        font-size: $font-size-base;
                        margin-bottom: 0.4rem;
                    }

                    .filter-control {
                        .ui-select-matches {
                            padding: 0;
                        }
                    }
                }
            }
        }

        &__overlay {
            position: absolute;
            left: 0;
            right: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            background: rgba($dericon-light-grey, 0.8);

            &__message {
                position: absolute;
                color: black;
                text-align: center;
                top: 50%;
                width: 100%;
                transform: translateY(-50%);
                padding: 1rem;
            }
        }
    }

    &__summary {
        $summary: &;

        @include make-col-ready($pd-gutter-widths);
        @include make-col(2, 5);
        position: relative;
        width: 40%;

        &-content {
            position: relative;
            display: flex;
            flex-direction: column;
            padding-bottom: 40px;
            max-height: 100%;

            &__part {
                padding-top: 1rem;
                flex: 0 1 auto;

                &:first-child {
                    padding-top: 0;
                    overflow: hidden;
                }

                &:nth-child(2) {
                    flex: 1 1 auto;
                    overflow-y: auto;
                    overflow-x: hidden;
                }

                app-product-designer-underlying-graph {
                    flex: 1;
                    display: flex;

                    .ngx-charts .refline-label {
                        font-size: 12px;
                    }
                }

                &__graph.sticky {
                    position: fixed;
                    min-height: 250px;
                    background: white;
                    z-index: 10;
                    padding-bottom: 1.225rem !important;
                    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
                }
            }
        }

        &__table-container {
            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li:not(:last-child) {
                    border-bottom: 1px solid $dericon-medium-light-grey;
                    padding-bottom: 0.5rem;
                    margin-bottom: 0.5rem;
                }
            }

            &.orientation--horizontal {
                /* IE-10/11 Grid */

                /* Flex - Cross Browser CSS */
                .product-designer__summary__table > thead {
                    display: flex;
                    flex-shrink: 0;
                    min-width: min-content;
                }
                .product-designer__summary__table > tbody {
                    display: flex;
                }
                .product-designer__summary__table > tbody > tr {
                    display: flex;
                    flex-direction: column;
                    min-width: min-content;
                    flex-shrink: 0;
                }
                .product-designer__summary__table > tbody > tr > td, .product-designer__summary__table > thead > th {
                    display: block;
                }
                .product-designer__summary__table caption {
                    display: block;
                }
            }
        }

        &__table.step-by-step-mode {
            .product-designer__summary__table__column-title {
                padding-left: 0.8rem;
            }

            tr.highlight {
                .product-designer__summary__table__column-title {
                    position: relative;
                    color: $brand-primary;

                    span {
                        position: relative;

                        &:before {
                            position: absolute;
                            left: -0.8rem;
                            top: 64%;
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 5px 0 5px 5px;
                            border-color: transparent transparent transparent $brand-primary;
                            transform: translateY(-7px);
                            content: " ";
                        }
                    }
                }
            }

            tr.row-click {
                cursor: pointer;
                &:hover {
                    td {
                        background: $dericon-light-grey;
                    }
                }
            }
        }

        &__table-details.table.table-condensed {
            background-color: $dericon-light-grey;
            margin-bottom: 0;

            td {
                padding: 0.65rem 0.75rem;
                word-break: break-word;
            }

            tr:last-child td {
                border-bottom: 1px solid #eceeef; // @NOTE: BORDER_COLOR
            }
        }

        .table-condensed.table &__table__column-title {
            padding-top: 22.4px - 1px;

            .mat-icon.material-icon {
                width: 14px;
                height: 14px;
                margin-left: 5px;
            }
        }

        &__details__item {
            position: relative;
            display: block;
            padding-right: 0;

            &:not(:last-of-type)::before {
                position: absolute;
                content: " ";
                background: #eceeef;
                height: 1px;
                bottom: 0;
                left: 0;
                right: -0.6rem;
            }

            &--cell {
                margin-bottom: 0.6rem;
                padding-bottom: 0.6rem;

                &:not(:last-child) {
                    &::after {
                        bottom: 0.1rem;
                    }
                }
            }

            &.border-bottom {
                margin-bottom: 0.6rem;
                padding-bottom: 0.6rem;


            }

            &__container {
                display: flex;
                width: 100%;
            }

            &__title {
                position: relative;
                //display: block;
                //text-transform: uppercase;
                //font-size: 0.6rem;
                //opacity: 0.5;
                flex-grow: 2;
            }

            &__value {
                padding-left: 1rem;
                text-align: right;
                word-break: normal;
            }

            #{$summary}__table-details.table.table-condensed &__table {
                margin-top: 0;
                margin-bottom: 0;

                th, td {
                    padding: 0.4rem 0.75rem 0.2rem 0.75rem
                }
            }

            &.markError {
                .product-designer__summary__details__item__container {
                    position: relative;

                    &::after {
                        content: " ";
                        position: absolute;
                        border: 1px solid red;
                        left: -0.3rem;
                        top: -0.6rem;
                        bottom: -0.5rem;
                        right: -0.6rem;
                    }
                }
            }
        }

        &--horizontal {
            &__container {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: stretch;
            }

            &__item {
                margin-right: 2rem;

                &__title {
                    margin: 1rem 0;
                }

                &__details {

                }
            }

            &__details {
                &__item {
                    &__title {
                    }

                    &__content {
                        background-color: $dericon-light-grey;
                        margin-bottom: 0;
                        padding: 0.5rem 0.5rem 0.5rem 0.5rem;

                        #{$summary}__details__item {
                            &:not(:last-of-type)::before {
                                position: absolute;
                                content: " ";
                                background: #eceeef;
                                height: 1px;
                                bottom: 0;
                                left: 0;
                                right: 0.2rem;
                            }
                        }
                    }
                }
            }
        }
    }

    &-mode-selection {
        &__card {
            &__part {
                display: flex;
            }

            &__icon {
                padding: 0 1rem 0 0;

                .mat-icon {
                    font-size: 40px;
                    width: 40px;
                    height: 40px;

                    color: $brand-primary;
                }
            }

            &__content {

            }
        }
    }


    &-issuer-table-cell {
        &__list {
            list-style: none;
            padding: 0;
            margin: 0;

            &-item {
                display: inline-block;
                background: $dericon-light-grey;
                padding: 6px 8px;
                margin: 3px;

                &.disabled {
                    opacity: 0.5;
                }
            }
        }

        &__success {
            color: green;
        }

        &__failure {
            color: red;
        }

        &__issuer-item {
            display: block;
            padding: 0;

            &__main {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            &__name {
                padding-left: 3px;
            }

            &--disabled {
                cursor: pointer;
                opacity: 0.6;
            }
        }
    }

    &-result {
        &__meta-actions-bar {
            $self: &;

            display: flex;
            justify-content: flex-end;
            margin-top: -2rem;
            margin-bottom: 1rem;

            &.float {
                position: absolute;
                top: 0.7rem;
                right: 2rem;
                margin-top: 0;
                margin-bottom: 0;

                #{$self}__action {
                    border: 0.0625rem solid $dericon-medium-light-grey;
                    margin-left: 0.2rem;

                    &:last-of-type {
                        float: none;
                        margin-bottom: 0;
                    }
                }

                .mat-icon {
                    height: 18px;
                    width: 18px;
                }
            }

            &__action {
                display: flex;
                align-items: center;
                color: #003f5e;
                background: transparent;
                cursor: pointer;
                transition: all 300ms;
                margin-bottom: 0.2rem;
                padding: 0.4rem 0.6rem;

                &--primary {
                    color: green;
                }

                &--secondary {
                    color: $dericon-medium-grey;
                }

                .mat-icon {
                    height: 22px;
                    width: 22px;
                    margin-left: 4px;
                }

                &:hover {
                    transition: border-color 200ms linear;
                    border-color: #003f5e;
                }
            }
        }

        &__table {
            tbody {
                tr {
                    transition: background-color 0.3s ease-in-out;

                    td {
                        vertical-align: middle;
                    }
                }

                tr:hover {
                    background-color: $dericon-light-grey;

                    td.table__column--frozen {
                        background-color: $dericon-light-grey;
                    }
                }
            }

            .table__column--frozen {
                position: -webkit-sticky;
                position: sticky;
                left: 0;
                width: 25rem;
                min-width: 25rem;
                max-width: 25rem;
                background-color: white;
                transition: background-color 0.3s ease-in-out;
                border-right: 2px solid $table-border-color;
                z-index: 1;
            }

            .table__column--bordered {
                border-right: 1px solid $table-border-color;
            }

            &__meta, &__isin, &__note {
                display: block;
            }

            &__meta {
                font-size: 0.75rem;
                padding-bottom: 0.3rem;
                font-weight: bold;
            }

            &__isin {
                padding-top: 0.2rem;
                font-weight: bold;
            }

            &__note {
                font-size: 0.75rem;
                padding-bottom: 0.5rem;
            }

            &__contact {
                font-size: 0.7rem;
                text-align: left;
                border-top: 1px solid $table-border-color;
                padding-top: 0.4rem;
                display: flex;
                flex-direction: row;

                &__icon {
                    margin-right: 0.5rem;

                    .mat-icon {
                        height: 16px;
                        width: 16px;
                        position: relative;
                        bottom: -3px;
                        left: 2px;
                    }
                }
            }

            &__column__highlight {
                color: black;
            }

            &__issued-box {
                border-left: 1px solid $table-border-color;
                border-top: 0;

                @include media-breakpoint-down(md) {
                    border-top: 1px solid $table-border-color;
                    border-left: 0;
                    padding-top: 1rem;
                    margin-top: 1rem;
                }

                &.top-border {
                    border-top: 1px solid $table-border-color;
                    border-left: 0;
                    padding-top: 1rem;
                    margin-top: 1rem;
                }
            }

            &__action {
                display: inline-block;
                color: #003f5e;
                border: 0.0625rem solid $dericon-medium-light-grey;
                background: transparent;
                cursor: pointer;
                transition: all 300ms;
                margin-bottom: 0.2rem;
                padding: 0 0.3rem 0.1rem 0.3rem;

                &:last-of-type {
                    margin-bottom: 0;
                }

                &--primary {
                    color: green;
                }

                &--secondary {
                    color: $dericon-medium-grey;
                }

                .mat-icon {
                    height: 16px;
                    width: 16px;
                    position: relative;
                    bottom: -3px;
                    left: 2px;
                }
            }

            &__auto-badge {
                $auto-badge-color: $badge-primary-bg;

                .label {
                    display: inline-block;
                    text-transform: uppercase;
                    position: relative;
                    bottom: -1px;
                    font-size: 0.6rem;
                    padding: 0.3rem 0.5rem 0 0.5rem;
                    border-left: 1px solid $auto-badge-color;
                    border-top: 1px solid $auto-badge-color;
                    border-right: 1px solid $auto-badge-color;

                    .bottom-border {
                        border-right: 1px solid $auto-badge-color;
                    }
                }

                .response-id {
                    cursor: copy;
                    position: relative;
                    display: inline-block;
                    border: 0.0625rem solid $dericon-medium-light-grey;
                    font-size: 0.7rem;
                    padding: 0 0.5rem;
                    margin-top: 0.3rem;
                }

                .mat-icon {
                    height: 13px;
                    width: 13px;
                    position: relative;
                    bottom: -3px;
                    left: 0px;
                }
            }
        }

        &-steps {
            padding: 10px;
            display: block;
            margin-bottom: 0;
            //background: #FFF;
            display: flex;
            flex-direction: column;
            align-items: center;

            &__popover {
                max-width: none;
                min-width: 600px;

                border: 1px solid rgba(0, 0, 0, 0.2);
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

                background-color: #fff;

                .product-designer-result-steps__step {
                    height: auto;
                    min-width: 10%;
                    max-width: 23%;

                    .step-title {
                        min-width: 10rem;

                        &:after {
                            display: none;
                        }
                    }
                }
            }

            &--single {
                &__step {
                    &:before {
                        content: " ";
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        display: inline-block;
                        font-size: 15px;
                        font-weight: 600;
                        text-align: center;
                        margin: 0;
                        border-radius: 50%;

                        background: #eaeaea;
                        color: #a9a9a9;
                        border: 1px solid #d9d9d9;
                    }

                    .step-icon {
                        z-index: 999;
                        position: absolute;
                        top: 0;
                        margin-top: 5px;
                        font-size: 18px;
                        color: #a9a9a9;
                        margin-left: -26px;

                        .mat-icon.material-icon {
                            height: 20px;
                            width: 20px;
                        }
                    }

                    .step-title {
                        position: absolute;
                        display: inline-block;
                        font-size: 11px;
                        font-weight: bold;
                        margin-top: 4px;
                        margin-left: -5px;
                        background: #eaeaea;
                        border: 1px solid #d9d9d9;
                        border-left: 0;
                        border-radius: 0 5px 5px 0;
                        padding: 4px 8px 2px 8px;
                    }

                    &.current-step {
                        color: #000;

                        &:before {
                            background-color: #f0ad4e;
                            border-color: lighten(#f0ad4e, 1%);
                            color: #FFF;
                        }

                        .step-icon {
                            color: #FFF;
                        }

                        .step-title {
                            background-color: #f0ad4e;
                            border-color: lighten(#f0ad4e, 1%);
                            color: #FFF;
                        }
                    }

                    &.completed-step {
                        color: #000;

                        &:before {
                            background-color: #92cf5c;
                            border-color: #83c944;
                            color: #FFF;
                        }

                        .step-icon {
                            color: #FFF;
                        }

                        .step-title {
                            background-color: #92cf5c;
                            border-color: #83c944;
                            color: #FFF;
                        }
                    }

                    &.error-step {
                        color: #000;

                        &:before {
                            background-color: #c81d1d;
                            border-color: #c81d1d;
                            color: #FFF;
                        }

                        .step-icon {
                            color: #FFF;
                        }

                        .step-title {
                            background-color: #c81d1d;
                            border-color: #c81d1d;
                            color: #FFF;
                        }
                    }
                }
            }

            &:before, &:after {
                content: " ";
                clear: both;
                display: block;
            }

            &__progressbar {
                width: 100%;
                counter-reset: step;
                list-style: none;
                margin: 0;
                padding: 0;
                clear: both;

                display: flex;
                justify-content: center;
            }

            &__step {
                list-style-type: none;
                float: left;
                max-width: 30%;
                min-width: 20%;
                position: relative;
                text-align: center;
                height: 115px;
                z-index: 10;

                &:before {
                    content: " ";
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    border: 1px solid #d9d9d9;
                    display: block;
                    font-size: 21px;
                    font-weight: 600;
                    text-align: center;
                    margin: 0 auto 10px;
                    border-radius: 50%;
                    background: #eaeaea;
                    color: #a9a9a9;
                }

                &:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 4px;
                    background-color: #eaeaea;
                    top: 19px;
                    left: 0;
                    z-index: -1;
                    border-top: 1px solid #d9d9d9;
                    border-bottom: 1px solid #d9d9d9;
                }

                &:first-of-type:after {
                    width: 50%;
                    left: 50%;
                }

                &:last-of-type:after {
                    width: 50%;
                }

                .step-title {
                    display: block;
                    font-size: 13px;
                    font-weight: 400;
                    color: #464646;
                    text-align: center;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    line-height: 20px;
                    min-width: 12rem;
                }

                .step-info {
                    display: block;
                    text-align: center;
                    font-size: 11px;
                    color: #a8a8a8;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    line-height: 15px;
                }

                .step-icon {
                    z-index: 1024;
                    position: absolute;
                    left: 50%;
                    top: 0;
                    margin-top: 7px;
                    font-size: 18px;
                    color: #a9a9a9;
                    margin-left: -12px;
                }

                &.current-step {
                    color: #000;

                    &:before {
                        background-color: #f0ad4e;
                        border-color: lighten(#f0ad4e, 1%);
                        color: #FFF;

                        //content: counter(step)!important;
                        //counter-increment: step;
                    }

                    &:after {
                        background-color: #f0ad4e;
                        border-color: lighten(#f0ad4e, 1%);
                    }

                    .step-icon {
                        color: #FFF;
                    }
                }

                &.completed-step {
                    color: #000;

                    &:before {
                        background-color: #92cf5c;
                        border-color: #83c944;
                        color: #FFF;
                    }

                    &:after {
                        background-color: #92cf5c;
                        border-color: #83c944;
                    }

                    .step-icon {
                        color: #FFF;
                    }

                    .step-title {
                        &:after {
                            position: absolute;
                            font-size: 14px;
                            color: #92cf5c;
                            content: "✓";
                            text-shadow: none;
                            left: 20px;
                        }
                    }
                }

                &.error-step {
                    color: #000;

                    &:before {
                        background-color: #c81d1d;
                        border-color: #c81d1d;
                        color: #FFF;
                    }

                    &:after {
                        background-color: #c81d1d;
                        border-color: #c81d1d;
                    }

                    .step-icon {
                        color: #FFF;
                    }

                    .step-title {
                        &:after {
                            position: absolute;
                            font-size: 14px;
                            color: #c81d1d;
                            content: "✕";
                            text-shadow: none;
                            left: 20px;
                        }
                    }
                }
            }

            $self: &;

            &.compact {
                #{$self}__step {
                    .step-title {
                        padding-left: 0;
                    }

                    &.completed-step {
                        .step-title {
                            &:after {
                                left: 0;
                            }
                        }
                    }

                    &.error-step {
                        .step-title {
                            &:after {
                                left: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    app-product-designer-editor-request {
        section.col-md.filter__col-1 {
            div.col-md.filter__col-1:last-child {
                padding-left: 4rem;
            }
        }

        .matrix-pricing-variance-container {
            margin-top: 0.5rem;
            align-items: center;

            > div:first-child {
                font-weight: bold;
                display: flex;
                align-items: center;
            }
        }
    }

    &-editor-request {
        &__form {
            &--MULTI_REQUEST {
                .col-md > .card-block > .row {
                    flex-direction: column;
                }

                .col-md > .card-block > .row > div.col-md.filter__col-1:last-child {
                    padding-left: 0.3125rem;
                }

                .col-md > .card-block > .row > div.col-md.filter__col-1:first-child > .card-form__section:nth-child(4) {
                    display: inline-block;
                }

                .col-md > .card-block > .row > div.col-md.filter__col-1:first-child > .card-form__section:nth-child(5) {
                    display: inline-block;
                    margin-left: 3rem;
                }

                app-filter-pd-multi-request-variances {
                    .form-group {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.product-designer-editor-steps {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 3rem;
    position: absolute;
    padding-right: 3.5rem;
    margin-top: -2.8rem;

    &__step {
        background: $gray-lighter;
        max-height: 10px;
        display: block;
        margin-left: 3px;
        flex: 1;
        overflow: hidden;
        transition: max-height 0.3s linear;
        cursor: pointer;

        > span {
            padding: 10px;
            display: inline-block;
        }

        &:first-child {
            margin-left: 0;
        }

        &:hover {
            max-height: 50px;
        }

        &.active {
            background: $brand-primary;
            color: white;
        }
    }
}

.product-designer-editor__step-buttons {
    width: 100%;

    &.pull-bottom {
        position: fixed;
        padding-right: 3.5rem;
        bottom: 2rem;
    }
}

.table tr td.product-designer-editor-survey__table__editor {
    padding: 1.5rem;

    .filter__form .card-form__section > .card-headline {
        font-size: 1rem !important;
        text-align: left;
    }

}

.filter-pd-underlying-extended {
    &__first-column {
        border-right: 1px solid $nav-tabs-border-color;

        app-tab-bar.nav.nav-tabs {
            border-bottom: 0;
        }
    }

    &__tab-panel {
        padding: 10px;

        &__actions {
            text-align: left;
            padding-top: 1rem;
        }
    }


    .table {
        .btn-sm.btn {
            padding: 0 0.3rem;
        }
    }
}

.filter-pd-capital-protection {
    .form-group-sub-control {
        margin-top: 0.7rem;
    }
}

.block-badge {
    //border: 1px solid $card-border-color;
    //background: white;
    display: flex;
    align-items: center;
    padding: 0.2rem;

    &__label {
        padding: 0.1rem 0.5rem 0 0.5rem;
        font-weight: bold;
    }

    &__view {
        padding: 0.1rem 0.5rem 0 0;
    }

    &__actions {
        .icon-label-button .icon-button {
            margin-right: 0;
            height: 1.2rem;
            width: 1.2rem;

            &__icon {
                height: 1.2rem;
                width: 1.2rem;
            }

            mat-icon.material-icon {
                height: 1.1rem;
                width: 1.1rem;
            }
        }
    }
}
