.entity-actions {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    height: 2.625rem;

    &__action-link {
        text-decoration: none;
    }

    &__action-disabled {
        text-decoration: none;
        color: $dericon-medium-light-grey;
        pointer-events: none;

        &:focus {
            color: $dericon-medium-light-grey;
        }
    }

    &__action {
        $ref: &;

        border-left: 1px solid $dericon-medium-light-grey;
        padding: 0 1rem;
        display: flex;
        align-items: center;

        &:first-child {
            border-left: none;
            padding-left: 0;
        }

        &__no-border {
            border-left: none;
            padding-left: 0;
        }

        &__active {
            text-decoration: underline;
        }

        &-icon {
            #{$ref}-link {
                display: flex;
                align-items: center;

                .mat-icon {
                    height: 15px;
                    width: 15px;
                    margin-right: 5px;
                    margin-top: 2px;
                }
            }
        }
    }
}

.entity-table-header {
    $b: &;

    &--decimal,
    &--percent,
    &--currency {
        text-align: right;

        #{$b}__meta {
            justify-content: flex-end;
        }
    }

    &__label {
        min-height: 2.125rem;
        color: inherit;
    }

    &__meta {
        display: flex;
        align-items: center;
    }

    &__info {
        display: inline-block;
        height: 0.875rem;
        width: 0.875rem;
        line-height: 0.875rem;
        text-align: center;
        color: $body-color;
        border: 1px solid $body-color;
        font-size: 0.625rem;

        &:before {
            content: 'i'
        }
    }

    &__sort {
        display: flex;
        flex-direction: column;
        margin-right: 0.25rem;
        height: 0.875rem;
        width: 0.875rem;
        align-items: center;
        justify-content: center;

        &:last-child {
            margin-right: 0;
        }

        &:before {
            display: block;
            content: '';
            width: 0;
            height: 0;
            border-left: 0.25rem solid transparent;
            border-right: 0.25rem solid transparent;
            border-bottom: 0.25rem solid $dericon-medium-light-grey;
            margin-bottom: 0.125rem;
        }

        &:after {
            display: block;
            content: '';
            width: 0;
            height: 0;
            border-left: 0.25rem solid transparent;
            border-right: 0.25rem solid transparent;
            border-top: 0.25rem solid $dericon-medium-light-grey;
        }

        &--asc {
            &:before {
                border-bottom: 0.25rem solid $brand-primary;
            }
        }

        &--desc {
            &:after {
                border-top: 0.25rem solid $brand-primary;
            }
        }
    }

    &.left-aligned {
        text-align: left;
        #{$b}__meta {
            justify-content: flex-start;
        }
    }

    &.right-aligned {
        text-align: right;

        #{$b}__meta {
            justify-content: flex-end;
        }
    }

    &.center-aligned {
        text-align: center;

        #{$b}__meta {
            justify-content: center;
        }
    }

}

.entity-table-row-flash-hightlight {
    background-color: $dericon-medium-light-grey;
}

.entity-table-row-highlighted {
    background-color: $dericon-light-grey;
}

td.entity-table__empty-message {
    text-align: center;
    font-style: italic;
    padding: 2rem;
    padding-bottom: 0;
    color: $text-muted;
}

.entity-table-cell {
    //&--decimal,
    //&--percent,
    //&--currency {
    //    text-align: right;
    //}
    &--composite {

        .additional {
            font-size: $font-size-sm;
            color: $dericon-medium-grey;
        }

    }

    &--composite-horizontal .entity-table-cell {
        display: inline-block;
    }

    &--right-aligned {
        text-align: right;
    }

    &--center-aligned {
        text-align: center;
    }

    &--muted {
        color: $dericon-medium-grey;
    }


    &--monospace {
        font-family: monospace;
        font-size: 0.8rem;
    }

    &--small {
        font-size: $font-size-sm;
    }

    &--meta {
        font-size: $font-size-sm * 0.8;
        padding-top: 0.4rem;
    }

    &--large {
        font-size: $font-size-lg;
    }

    &--bright {
        color: white;
        .entity-table-cell {
            &--muted {
                color: white;
                opacity: 0.8;
            }
        }

        a {
            color: white;
        }
    }

    &--padding {
        display: inline-block;
        padding: 2px 5px;
    }

    &--positive {
        display: flex;
        line-height: 1;

        & > .mat-icon {
            background: $brand-muted;
            border-radius: 50%;
            color: $dericon-medium-light-grey;
            width: 1rem;
            height: 1rem;
            padding: 0.125rem;
            margin-right: 0.2rem;
        }

        &.add-color {

            & > .mat-icon {
                background: $toast-success-color;
                color: white;
            }
        }

        span {
            position: relative;
            top: 0.05rem;
        }
    }

    &--negative {
        display: flex;
        line-height: 1;

        & > .mat-icon {
            background: $brand-muted;
            border-radius: 50%;
            color: $dericon-medium-light-grey;
            width: 1rem;
            height: 1rem;
            padding: 0.125rem;
            margin-right: 0.2rem;
        }

        &.add-color {

            & > .mat-icon {
                background: $toast-error-color;
                color: white;
            }
        }

        span {
            position: relative;
            top: 0.05rem;
        }
    }

    &--heading {
        font-size: var(--body-large) !important;
        font-weight: bold !important;
        padding-bottom: 0.5rem !important;
        color: inherit !important;
    }
}

.entity-table-selectbox-cell,
.entity-table-text-field-cell {
    .form-group {
        margin-bottom: 0;
        width: 90%;
    }
}

.entity-table-equivalent-products-cell {
    .products-list {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
            border-bottom: 1px solid $dericon-medium-light-grey;

            &:not(:first-of-type) {
                padding-top: 0.2rem;
            }

            &:last-of-type {
                border-bottom: 0;
            }
        }
    }
}

td.entity-table-cell {
    position: relative;;

    &.green-flag {
        &:after {
            position: absolute;
            bottom: 0;
            left: 0;

            width: 0;
            height: 0;
            content: '';
            display: block;

            border-bottom: 10px solid #2ECC40;
            border-right: 10px solid transparent;
        }
    }

    &.yellow-flag {
        &:after {
            position: absolute;
            bottom: 0;
            left: 0;

            width: 0;
            height: 0;
            content: '';
            display: block;

            border-bottom: 10px solid #FFDC00;
            border-right: 10px solid transparent;
        }
    }

    &.red-flag {
        &:after {
            position: absolute;
            bottom: 0;
            left: 0;

            width: 0;
            height: 0;
            content: '';
            display: block;

            border-bottom: 10px solid #FF4136;
            border-right: 10px solid transparent;
        }
    }
}

.entity-table {
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: 0;

    &--fixed {
        table-layout: fixed;
    }

    .app-entity-table-row {
        background-color: white;
        transition: background-color 0.3s linear;

        &.entity-table-row-highlighted {
            background-color: $dericon-light-grey;
        }

        &.colored-flash {
            animation: entityRowColoredFlash linear 1.5s;
        }

        &.vmiddle {
            td {
                vertical-align: middle;
            }
        }

        &--conflict {
            position: relative;

            td {
                $border-color: #ebcccc;
                color: #a94442;
                background-color: #f2dede;

                border-top: 1px solid $border-color;
                border-bottom: 1px solid $border-color;

                &:last-of-type {
                    position: relative;
                    padding-right: 1.5rem;

                    &:after {
                        position: absolute;
                        content: " ! ";
                        color: red;
                        font-size: 1.5rem;
                        font-weight: bold;
                        top: 50%;
                        right: 1rem;
                        margin-top: -0.8rem;
                    }
                }
            };
        }
    }

    .entity-table-multi-row-cell {
        &__line {
            > div {
                display: inline-block;
            }

            .label {
                padding-right: 0.1rem;
            }

            .separator {
                padding-right: 0.2rem;
                padding-left: 0.2rem;
            }
        }
    }

    .entity-table-multi-row-comment-cell {

        &__line {
            &:not(:last-child) {
                margin-bottom: 0.375rem;
            }

            > div {
                background-color: #FFFFFF;
                padding: 0.375rem;
                border-style: solid;
                border-width: 1px 1px 0 1px;
                border-color: rgba(0, 0, 0, 0.15);
            }

            .separator {
                padding-right: 0.2rem;
                padding-left: 0.2rem;
            }
        }
    }

    &--highlightable {
        .app-entity-table-row {
            cursor: pointer;

            &-hover,
            &:not(.selected):hover {
                transition: none;
                background-color:  $dericon-light-grey;
            }
        }
    }

    &--selectable {
        .app-entity-table-row {
            cursor: pointer;

            &.selected {
                transition: background-color 0.2s linear;
                background-color: $custom-entity-table-row-selected-bg;
            }
        }
    }

    tbody tr:first-child td {
        border-top: none;
    }

    thead {
        background: $white;
    }

    thead th {
        vertical-align: top;
        padding-bottom: 0.25rem;
        z-index: 510;
        background: $white;
        background-clip: padding-box;
    }

    &__remove-button {
        margin-right: 1rem;

        .mat-icon.derifin-icon {
            width: 10px;
            height: 10px;
        }
    }

    &__top-spacing {
        margin-top: 1.5rem;
    }

    &-action-cell {
        &__action {
            white-space: nowrap;
        }
    }
}

.entity-widget__card--muted {
    .entity-table {
        .app-entity-table-row {
            background: $brand-muted;
        }

        .app-entity-table-row-selected {
            background: white;
        }

        .app-entity-table-row-selected--chart {
            background: white;
            position: relative;

            &:after {
                position: absolute;
                width: 15px;
                top: 0;
                bottom: 0rem;
                right: -9px;
                content: " ";
                background: white;
            }
        }
    }
}

@keyframes entityRowColoredFlash {
    0% {
        background-color: #aaccac;
    }

    100% {
        background-color: $white;
    }
}
/** Entity Highlights **/

.entity-highlights {
    display: block;

    &__column {
        @include make-col-ready();
        @include make-col(1, 4);

        /*@include media-breakpoint-up('xxl') {
            @include make-col(1, 6);
        }*/
    }

}

.entity-highlight {
    display: block;

    &__content {
        text-align: left;
        color: $brand-primary;
        font-size: $font-size-h6;
    }

}
