/**
    File for styling Angular Material components
*/

button:focus {
    outline: none;
}

.cdk-overlay-container {
    z-index: 2000;
}

.mdc-button {
    font-family: 'Inter', sans-serif;
    letter-spacing: normal;
}

.mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
    background-color: transparent !important;
}

.mat-calendar {
    font-family: 'Inter', sans-serif;
}

.mat-calendar-period-button {
    margin: 0 !important;
}

.mat-datepicker-popup {
    box-shadow: none;

    .mat-datepicker-content {
        .mat-calendar-controls {
            .mat-button-ripple {
                display: none;
                border-radius: 0;
            }

            .mat-calendar-period-button {
                border-radius: 0;

                &:hover {
                    background-color: rgba(0, 0, 0, .04)
                }
            }


            .mat-calendar-previous-button {
                border-radius: 0;

                &:hover {
                    background-color: rgba(0, 0, 0, .04)
                }
            }

            .mat-calendar-today-button {
                border-radius: 0;

                &:hover {
                    background-color: rgba(0, 0, 0, .04)
                }
            }

            .mat-calendar-next-button {
                border-radius: 0;

                &:hover {
                    background-color: rgba(0, 0, 0, .04)
                }
            }
        }

        border-radius: 0;
        border: 1px solid rgba(0, 0, 0, 0.12);
        box-shadow: none;

        .mat-calendar-body-cell-content {
            border-radius: 0;

            &.mat-calendar-body-selected {
                background-color: $brand-primary;
            }
        }
    }
}


.mdc-tooltip__surface {
    border-radius: 0 !important;
}
