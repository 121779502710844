.landing-carousel {
    &__inner {
        height: 100%;
    }

    &__item {
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: opacity 1s ease-in-out;
        position: absolute;
        z-index: 0;
        display: block;
        overflow: hidden;

        &--active {
            z-index: 1;
            opacity: 1;
        }

        &__image {
            min-width: 100%;
            min-height: 100%;
        }


        &__caption {
            position: absolute;
            padding: 45px 0;
            background-color: rgba($color: #000000, $alpha: 0.6);
            text-align: left;
            left: 0;
            right: 0;
            bottom: 15%;
            color: #ffffff;

            * {
                padding-right: 0.3125rem;
                padding-left: 0.3125rem;
                display: block;
                margin: 0 auto;

                @media (min-width: 576px) {
                    width: 540px;
                }

                @media (min-width: 768px) {
                    width: 720px;
                }

                @media (min-width: 992px) {
                    width: 960px;
                }

                @media (min-width: 1270px) {
                    width: 1268px;
                }

                @media (min-width: 1720px) {
                    width: 1690px;
                }
            }
        }
    
        &__contact {
            text-align: center;
            left: 0;
            right: 0;
            bottom: 1%;
            position: absolute;
            color: #ffffff;
            font-size: $font-size-xs;
        }
    }
}