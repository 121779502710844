/**
 * Styles for menu component, including main-menu and inline-menu
 */


/** MenuComponent **/

.menu {
    $block: &;

    &__container {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__row {
        position: relative;
        display: flex;
        align-items: center;
        color: $dericon-landing-grey;
        height: $menu-row-height;
        transition: color 250ms;

        &--current {
            color: inherit;
        }

    }

    &--inline {

        #{$block}__container {
            justify-content: flex-start;
        }

        #{$block}__row {
            height: auto;
            min-height: $menu-row-height;
        }

    }

    &--inverse {
        #{$block}__row {
            color: rgba(255, 255, 255, 0.5);

            &--current {
                color: inherit;
            }
        }
    }

}


/** MainMenuComponent **/

app-main-menu {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $brand-primary;
    color: $white;
    z-index: 1000;

    .navbar {
        position: absolute;
        width: 100%;
        color: $white;
    }

    .menu-item {
        &__link {
            &:focus {
                outline:none;
            }

            &::-moz-focus-inner {
                border:0;
            }

            &:hover {
                color: $white;
            }
        }

        &--active {
            position: relative;

            &:after {
                content: " ";
                width: 4px;
                height: 4px;
                background: white;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }

            &, &:active, &:focus {
                color: $white;
            }
        }
    }
}

/** MenuItemComponent **/

.menu-item {
    display: flex;
    flex-direction: column;
    width: $menu-item-width;
    text-align: center;

    &--active,
    &--current {
        color: $brand-primary;
    }

    &__icon {
        display: flex;
        justify-content: center;
        margin: auto;
        margin-bottom: 0.75rem;
        cursor: pointer;
    }

    &__label {
        display: block;
        height: 1.5rem;
        cursor: pointer;
    }

    &__link {
        color: inherit;
        padding: $menu-item-padding-x $menu-item-padding-y;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: none;
            color: inherit;
        }

        &:hover {
            color: $brand-primary;
        }
    }

    &__submenu {
        border-top: 1px solid;
    }

    &__submenu-container {
        padding: $menu-item-padding-x $menu-item-padding-y;
    }
}


/** SubmenuItemcomponent **/

.submenu-item {
    display: block;
    margin-bottom: 0.125rem;

    &__link {
        display: block;
        color: inherit;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
            color: inherit;
        }

        &--active:before {
            content: '›'
        }

        &--active:after {
            content: '‹'
        }
    }
}
