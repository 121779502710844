.mat-accordion {
    .mat-expansion-panel {
        border-bottom: 1px solid #ddd;

        &:first-of-type, &:last-of-type {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        }

        &:last-of-type {
            border-bottom: 0;
        }

        .mat-expansion-panel-header {
            font: normal 0.85rem/1.0625 $font-family-sans-serif;

            .mat-expansion-panel-header-title {
                font-weight: bold;
            }


        }
        .mat-expansion-panel-content {
            font: normal 0.85rem/1.0625 $font-family-sans-serif;
        }

        &.mat-expanded {
            background: $dericon-light-grey;
        }
    }
}

.accordion {
    &__panel {
        $self: &;
        border-bottom: 1px solid #ddd;
        overflow: hidden;

        &:last-of-type {
            border-bottom: 0;
        }

        &__header {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 24px;
            border-radius: inherit;
            height: 48px;
            cursor: pointer;

            &:hover {
                background: $dericon-light-grey;
            }

            &__title {
                display: flex;
                flex: 1;
                flex-direction: row;
                overflow: hidden;
                padding-right: 16px;
                font-weight: bold;
            }

            &__indicator {
                &:after {
                    border-style: solid;
                    border-width: 0 2px 2px 0;
                    content: '';
                    display: inline-block;
                    padding: 3px;
                    transform: rotate(45deg);
                    transition: transform 0.2s linear;
                    vertical-align: middle;
                }
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            overflow: visible;
            max-height: 0px;

            transition: max-height 0.2s linear;

            &__body {
                padding: 0 24px 16px;
            }
        }

        &.expanded {
            background: $dericon-light-grey;

            #{$self}__content {
                max-height: 900px;
            }

            #{$self}__header__indicator {
                &:after {
                    transform: rotate(225deg);
                }
            }
        }
    }
}
