/**
 * Base stylings
 */

 html, body {
    height: 100%;
}

:root {
    --headline-small: 1.125rem;
    --body-small: 0.75rem;
    --body-medium: 0.8125rem;
    --body-large: 0.9375rem;
}

.mat-icon.material-icon {
    height: 24px;
    width: 24px;
}

.mat-icon.derifin-icon {
    width: 25px;
    height: 25px;
}

/** Search Icon **/
.form-quick-view__button__icon {
    mat-icon.material-icon {
        width: 24px;
        height: 24px;
    }
}

.menu-item__icon {
    mat-icon.material-icon {
        height: 38px;
        width: 38px;
    }

    mat-icon.derifin-icon {
        height: 32px;
        width: 32px;
        margin: 3px;
    }
}


.scroll-top-button {
    mat-icon.material-icon {
        width: 24px;
        height: 24px;
    }
}


.grayscale {
    transition: filter 0.2s linear, background linear 0.2s, src linear 0.2s;

    filter: gray; // IE
    -webkit-filter: grayscale(1); // Old WebKit
    -webkit-filter: grayscale(100%); // New WebKit
    filter: url('fonts/filters.svg#grayscale'); // Firefox 3.5+
    filter: grayscale(100%); // Current draft standard

    &.disable-hover:hover {
        filter: none; // Applies to FF + IE
        -webkit-filter: grayscale(0);
    }

    &--on-hover {
        filter: none; // Applies to FF + IE
        -webkit-filter: grayscale(0);
        transition: filter 0.2s linear, background linear 0.2s, src linear 0.2s;

        &:hover {
            filter: gray; // IE
            -webkit-filter: grayscale(1); // Old WebKit
            -webkit-filter: grayscale(100%); // New WebKit
            filter: url('fonts/filters.svg#grayscale'); // Firefox 3.5+
            filter: grayscale(100%); // Current draft standard
        }
    }
}

p {
    hyphens: auto;
    hyphenate-limit-chars: 6 3 3;
    -webkit-hyphens: auto;
    -webkit-hyphenate-limit-chars: 6 3 3;
}

a.link-icon {
    padding-bottom: 4px;
    display: inline-block;

    app-icon {
        mat-icon.material-icon {
            height: 15px;
            width: 15px;
            position: relative;
            bottom: -3px;
        }
    }
}

.badge-indicator {
    border-radius: 50%;
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 0.3rem;

    &:empty {
        display: inline-block;
    }
}

.badge-superscript {
    border-radius: 50%;
    display: inline-block;
    position: relative;
    width: 1rem;
    height: 1rem;
    font-size: 0.7rem;
    line-height: 0.9rem;
    top: -0.3rem;
}

.alert-primary {
    @include alert-variant(white, $brand-primary, $brand-primary);
    padding: 0.75rem;
}

.badge-primary-inverse {
    color: $brand-primary;
    border: 1px solid $brand-primary;
    background-color: white;
}

.table-sticky {
    thead th {
        position: sticky;
        top: 0;
    }
}

.table-border-left {
    border-left: 1px solid $table-border-color;
}

.table-border-top {
    border-top: 1px solid $table-border-color;
}
