
.wms-dialog {
    .mat-mdc-dialog-container {
        color: #FFFFFF;

        .wms-dialog-inner {
            background: $brand-primary;
            height: 100%;
            display: flex;
            flex-direction: column;

            .headline {
                color: #FFFFFF;
                font-size: $font-size-h4;
                text-transform: uppercase;
            }
        }

        .modal-body {
            flex: 1;
            margin: 0 auto;
        }

        .loading-dialog {
            background-color: rgba(255, 255, 255, 0.75);
        }
    }

    &.inverse {
        .mat-mdc-dialog-container {
            color: $dericon-dark-grey;
        }
    }
}


.widget-dialog {
    display: block;

    &__products {
        background-color: $brand-muted;
        margin-top: 1rem;
        padding: 0.625rem,
    }

    > .container {
        height: calc(100vh - 80px);
        width: 100%;
        position: absolute;
        margin: 0;
        padding: 0;
        overflow-y: auto;

        .modal-body {
            margin: 0 auto;
            padding: 50px 0;
            max-width: 70%;
            .widget-container {
                color: $body-color;
            }
        }
    }

    .navbar {
        position: absolute;
        top: 0;
        width: 100%;
        height: 160px;
        z-index: 1;
    }

    .modal-footer {
        background: $brand-muted;
        width: 100%;
        height: 80px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        button {
            margin-right: 15px;
        }
    }
}