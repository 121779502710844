@use "sass:math";

.small-layout, .small-layout ~ .cdk-drag-preview  {
    .custom-control-indicator {
        top: (($line-height-base  - $custom-control-indicator-size) * 0.5) * $small-layout-scale;
    }

    .custom-control-description {
        line-height: $line-height-base * $small-layout-scale;
    }

    .custom-control {
        align-items: center;
    }

    .custom-controls-stacked .custom-control {
        padding-top: 0.2rem;
    }
}

.small-layout ~ .cdk-drag-preview {
    .card {
        .card-block {
            padding: 1rem;
            padding-bottom: 0.1rem;

            .card-title {
                margin-bottom: 1rem;
            }

            .card-form__section {
                margin-bottom: 0.8rem;

                .card-headline {
                    font-size: 1rem;
                }

                .filter-control {
                    .ui-select-matches {
                        padding: 0;
                    }
                }
            }
        }
    }
}

.small-layout, .small-layout ~ .cdk-drag-preview  {
    font-size: $font-size-sm;

    .navbar {
        padding: 3rem * $small-layout-margin-scale 0;
    }

    .card-block {
        padding: 2.5rem * $small-layout-margin-scale-huge;
        padding-bottom: 0.1rem;
    }

    .watchlists-group-card {
        &__card {
            padding-bottom: 2.5rem * $small-layout-margin-scale-huge;

            p {
                padding-right: 1.5rem;
                padding-top: 0.2rem;
            }
        }

        &__product-count {
            font-size: 2rem;
        }
    }

    .entity-widget__card--flat {
        .card-block {
            padding: 0 0 2rem 0;
        }
    }

    .card-headline {
        font-size: $card-headline-font-size * $small-layout-scale;
        margin-bottom: $card-headline-margin-y * $small-layout-scale;
    }

    .card-title {
        margin-bottom: $card-title-margin-y * $small-layout-margin-scale;
    }

    .card-form__section {
        margin-bottom: 2.5rem * $small-layout-margin-scale-huge;
    }

    .ui-select-choices-row {
        .dropdown-item {
            font-size: 0.9rem;
            line-height: 1;
        }
    }

    .overlay__content.product-details .container {
        max-width: 1268px;
        min-width: 1268px;
    }

    .watchlists-group-card__product-count {
        font-size: 1.5rem;
    }
}

//color: $brand-primary;
//margin-bottom: $card-headline-margin-y;
//font-size: $card-headline-font-size;
