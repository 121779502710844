@mixin triangle($color, $left: true) {
    width: 0;
    height: 0;
    border-style: solid;

    @if($left) {
        border-width: 25px 11px 25px 0;
        border-color: transparent $color transparent transparent;
    } @else {
        border-width: 25px 0 25px 11px;
        border-color: transparent transparent transparent $color;
    }

    content: '';
    top: 5px;
    position: absolute;
    @content;
}

@mixin beforeBoundary($color, $left: true) {
    @include triangle($color, $left) {
        @if($left) {
            left: -11px;
        } @else {
            left: 0;
        }
    }
}

@mixin afterBoundary($color: white, $left: true) {
    @include triangle($color, $left) {
        @if($left){
            right: 0;
        } @else {
            right: -11px;
        }
    }
}

@mixin boundary($color, $left: true, $right: false, $zIndex: 0) {
    margin-left: 0.2rem;
    height: 60px;
    background: $color;
    position: relative;
    border-radius: 6px;
    z-index: $zIndex;

    padding-top: 0.7rem;
    padding-bottom: 0.7rem;

    @if($left) {
        padding-left: 1rem;
    } @else {
        padding-left: 2rem;
    }

    @if($right) {
        padding-right: 1rem;
    } @else {
        padding-right: 2rem;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
        @if($left){
            @include beforeBoundary($color);
        } @else {
            @include beforeBoundary(white, false);
        }
    }

    &:after {
        @if($right) {
            @include afterBoundary($color, false);
        } @else {
            @include afterBoundary();
        }
    }
}

.assets-deviation-selector {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;

    &__row {
        display: flex;
        flex-direction: row;
        height: 65px;
        width: 100%;
        margin-bottom: 0.2rem;
        align-items: center;

        > * {
            flex: 1;
            text-align: center;
        }

        &--info {
            height: 2rem;
            display: flex;
            margin-bottom: 0.4rem;
            margin-top: 0.4rem;
        }
    }

    &__header-row {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 0.2rem;
        align-items: center;
        justify-items: center;

        > * {
            flex: 1;
            text-align: center;

            padding: 1rem;
            font-size: 1rem;
        }

        .custom-controls-stacked {
            align-items: center;
            font-size: 80%;
        }
    }

    &__header-col-danger {
        flex: 0 1 50px;
    }

    &__label,
    &__header-col-label {
        font-size: 14pt;
        flex: 0 1 20%;
    }

    &__info {
        margin-left: 20%;
        flex: 1;
        border: 1px solid $gray-lighter;
        opacity: 0.6;
        border-radius: 3px;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;

        > * {
            flex: 1;
            font-size: 1rem;

            &.red {
                color: red;
            }
        }

        &__min-danger,
        &__max-danger {
            flex: 0 1 50px;
        }

        &__neutral {
            text-align: center;
        }

        &__max-danger,
        &__max-warn,
        &__max-ok {
            text-align: right;
        }
    }

    &__min-danger {
        @include boundary(#ff6b6b, true, false, 0);
        flex: 0 1 50px;
    }

    &__min-warn {
        @include boundary(#ffd280, true, false, 1);
    }

    &__min-ok {
        @include boundary(#6db36d, true, false, 2);
    }

    &__neutral {
        @include boundary(#6db36d, true, true, 3);
    }

    &__max-ok {
        @include boundary(#6db36d, false, true, 2);
    }

    &__max-warn {
        @include boundary(#ffd280, false, true, 1);
    }

    &__max-danger {
        @include boundary(#ff6b6b, false, true, 0);
        flex: 0 1 50px;
    }

    .percent-input {
        position: relative;
        font-size: $font-size-h6 !important;
        max-width: 6rem;
        padding-right: 1rem;
        padding-left: 1rem;
        padding-top: 0.35rem!important;
        padding-bottom: 0.35rem !important;
        text-align: right;

        &--component {
            position: relative;
        }

        &--prefix {
            padding-left: 0.1rem;
        }

        &__prefix {
            position: absolute;
            left: 0.5rem;
            top: 0;
            height: 100%;
            display: flex;
            align-items: center;
            padding-top: 0.3rem;
            padding-bottom: 0.17rem;
            font-size: 0.9rem;
            pointer-events: none;
            opacity: 0.5;
        }

        &__suffix {
            position: absolute;
            right: 0.5rem;
            top: 0;
            height: 100%;
            display: flex;
            align-items: center;
            padding-top: 0.17rem;
            padding-bottom: 0.17rem;
            font-size: 0.9rem;
            pointer-events: none;
            opacity: 0.5;
        }
    }
}

.assets-deviation-summary {
    margin-left: 20%;
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 15pt;

    &__icon {
        margin-left: 0.3rem;

        color: red;

        &.success {
            color: green;
        }
    }
}

.assets-deviation-headline {
    margin-left: 2rem;
}

.portfolio-form {
    &__column {
        display: flex;
        flex-direction: column;
    }

    &__block-actions {
        padding: 1.225rem;
    }
}

.card-column--vertical {
    flex-direction: column;

    > .split-card {
        margin-bottom: 0.5rem;
    }
}
