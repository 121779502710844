.modal__navbar  {
    position: absolute;
    width: 100%;
    top: 0;
    height: 160px;
}

.modal-body {
    flex: initial;
    max-width: 32rem;
    position: static;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.modal-loading-text {
    padding-top: 1rem;
    text-align: center;

    color: $brand-primary;
    font-weight: bold;
}


.modal-form {
    &__title {
        font-size: $font-size-h2;
        margin-bottom: 2rem;
    }

    &__subtitle {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    &__title-meta {
        font-size: 1.25rem;
        position: relative;

        &__text {
            &--green {
                color: lightgreen;
            }

            &--faded {
                color: $dericon-medium-grey;
            }
        }
    }

    &__form {
        margin-bottom: 2rem;
    }

    &__forgot-credentials {
        margin-bottom: 2rem;
        font-weight: bold;
    }

    &__error {
        padding-bottom: 1rem;
    }

    &__field {
        &--large {
            min-width: 16rem;
            width: auto;
        }
    }

    &__spinner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: fade_out($brand-primary, 0.25);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10000;
    }
}

.modal-actions {
    display: flex;

    &__action {
        color: inherit;
        text-decoration: none;
        margin-right: 1rem;

        &:after {
            content: '›';
        }

        &:hover,
        &:active,
        &:focus {
            text-decoration: underline;
            color: inherit;
        }
    }

    &__yes {
        background: #f44336;
        padding: 10px;
    }

    &__no {
        padding: 10px;
    }
}


.add-user-dialog, 
.product-designer-editor-survey-create-dialog, 
.product-designer-editor-result-issue-dialog {
    .modal-actions {
        margin-top: 2rem;

        > a {
            padding: 10px 10px 8px 10px;
        }

        &__complete {
            background: lighten($brand-primary, 10%)
        }
    }
}

.add-user-dialog {

    > .modal-body {
        max-width: 72rem;
        flex: 1 1 auto;
    }

    .add-user-dialog__icon-button {
        &, i {
            color: white;
            margin-right: 0;
        }

        i > .mat-icon {
            font-size: 20px;
        }
    }

    .modal-actions {
        margin-top: 2rem;

        > a {
            padding: 10px;
        }

        &__complete {
            background: lighten($brand-primary, 10%)
        }
    }

    &__table {
        &__header {
            padding: 5px 10px;
        }

        &__row {
            transition: background 0.2s linear;
            &:hover, &:active, &:focus {
                background: rgba(lighten($brand-primary, 10%), 0.7);
            }

            &:last-child {
                margin-bottom: 1rem;
            }
        }

        &__col {
            padding: 5px 10px;

            &--remove {
                padding-top: 5px;
                max-width: 30px;
                min-width: 30px;
            }
        }
    }
}
