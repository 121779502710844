.risk-manager-general-settings {
    margin-top: 2rem;
    margin-bottom: 5rem;

    &__heading {
        font-size: $font-size-base;
        letter-spacing: 1px;
        font-weight: $font-weight-normal;
        text-transform: uppercase;
        margin-bottom: $card-title-margin-y;
    }

    &__content {
        display: flex;
        flex-direction: row;
    }

    &__entry {
        position: relative;
        width: 25%;
        display: flex;
        flex-direction: column;

        &:last-child {
            width: auto;
            flex: 1;
        }
    }

    &__entry-title {
        border-bottom: 1px solid $dericon-medium-light-grey;
        margin-bottom: 1rem;
        height: 3rem;
    }

    &__entry-content {
        padding-right: 20px;
    }

    &__entry-description {
        font-size: $font-size-sm;
        color: $text-muted;
    }

    &__actions {
        border-top: 1px solid $dericon-medium-light-grey;
        margin-top: 1.25rem;
        display: flex;
        flex-direction: row;
        width: 200px;
    }

    &__action {
        float: right;
        margin-top: 2rem;
        cursor: pointer;
    }
}


.risk-manager-product-selector {
    margin-bottom: 2rem;

    &__column {

        display: flex;
        flex-direction: column;

        & > * {
            flex: 1;
        }

        .filter {
            margin-bottom: 0;
        }
    }

}
