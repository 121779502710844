/**
 * Variables
 */

// Replace `$search` with `$replace` in `$string`
// @author Hugo Giraudel
// @param {String} $string - Initial string
// @param {String} $search - Substring to replace
// @param {String} $replace ('') - New value
// @return {String} - Updated string
@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}


// Colors

$white: #fff !default;
$black: #000;

$dericon-blue: #2C3E5D;
$dericon-light-grey: #F8F8F8;
$dericon-dark-grey: #595B5B;
$dericon-landing-grey: #c0c1c5;
$dericon-medium-light-grey: #dbdbdb;
$dericon-medium-grey: #919191;

$brand-primary: $dericon-blue !default;
$brand-muted: $dericon-light-grey !default;

$body-color: $dericon-dark-grey !default;
$text-muted: $dericon-medium-grey !default;

$toast-success-color: green;
$toast-error-color: #BD362F;

// small scale
$small-layout-scale: 0.85;
$small-layout-margin-scale: 0.70;
$small-layout-margin-scale-huge: $small-layout-margin-scale * 0.70;

// Fonts
$font-family-sans-serif: 'Inter', sans-serif !default;
$font-size-base: 0.9375rem;
$font-size-xs: $font-size-base - 0.1875rem;
$font-size-sm: $font-size-base - 0.125rem;
$font-size-h1: 2.3125rem;
$font-size-h2: 2.1875rem;
$font-size-h3: 1.8125rem;
$font-size-h4: 1.5rem;
$font-size-h5: 1.375rem;
$font-size-h6: 1.125rem;

$line-height-base: 1.375 !default;

// General Options
$enable-rounded: false !default;

// Grid

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1270px,
    xxl: 1720px
) !default;

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1268px, // 1258px + 2 x 5px gutter
    xxl: 1690px // 1680px + 2 x 5px gutter
) !default;

$grid-gutter-width-base: 0.625rem !default;

$grid-gutter-width-large: 2rem !default;

$grid-gutter-widths: (
    xs: $grid-gutter-width-base,
    sm: $grid-gutter-width-base,
    md: $grid-gutter-width-base,
    lg: $grid-gutter-width-base,
    xl: $grid-gutter-width-base
) !default;

$grid-gutter-widths-large: (
    xs: $grid-gutter-width-large,
    sm: $grid-gutter-width-large,
    md: $grid-gutter-width-large,
    lg: $grid-gutter-width-large,
    xl: $grid-gutter-width-large
) !default;

// Layout
$layout-padding-bottom: 1rem !default;

$header-bar-logo-height: 2.0625rem !default;

// Buttons

$btn-line-height: 1.4285714286;
$btn-padding-x: 0.75rem;
$btn-padding-y: 0.375rem;
$icon-button-size: 2.125rem !default;

// Forms

$input-line-height: 1.4285714286;
$input-height: 2rem;
$input-padding-x: 0.75rem;
$input-padding-y: 0.375rem;
$input-bg: transparent !default;
$input-border-focus: $brand-primary !default;
$custom-select-indicator: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
$custom-select-bg-size: 16px 12px;
$custom-select-focus-border-color: $brand-primary;

$custom-control-indicator-bg: transparent !default;
$custom-control-active-indicator-bg: $dericon-medium-light-grey !default;
$custom-control-border: 1px solid $dericon-medium-light-grey !default;
$custom-control-border-checked: 1px solid $brand-primary !default;
$custom-control-checked-indicator-bg: transparent !default;
$custom-control-checked-indicator-color: $brand-primary !default;
$custom-control-indicator-color: $dericon-medium-light-grey !default;
$custom-control-indicator-bg-size: 100% 100% !default;
$custom-control-focus-indicator-box-shadow: none !default;
$custom-switch-width: 2rem !default;
$custom-switch-checked-icon: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$custom-control-checked-indicator-color}'/%3E%3C/svg%3E"), "#", "%23") !default;
$custom-switch-icon: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$custom-control-indicator-color}'/%3E%3C/svg%3E"), "#", "%23") !default;

// Navbar

$navbar-padding-y: 3rem !default;
$navbar-padding-x: 0 !default;
$navbar-inverse-color: $white !default;
$nav-disabled-link-color: fade_out($body-color, 0.5) !default;
$nav-link-padding: .5rem 1rem;

// Tabs

$nav-tabs-active-border-width: 0.125rem !default;
$nav-tabs-active-link-color: $brand-primary !default;
$nav-tabs-distance: 1.5rem !default;

// Cards

$card-cap-bg: transparent !default;
$card-spacer-x: 2rem !default;
$card-spacer-y: 1.5rem !default;
$card-title-margin-y: 1.75rem !default;
$card-headline-margin-y: 1.25rem !default;
$card-headline-font-size: 1.75rem !default;
$card-divider-color: $dericon-medium-light-grey !default;

// Tooltips

$tooltip-opacity: 0.8 !default;
$tooltip-padding-y: 0.75rem !default;

// Menu

$menu-item-padding-x: 1rem !default;
$menu-item-padding-y: 0.5rem !default;
$menu-item-width: 9.5rem !default;
$menu-row-height: 12rem !default;

// Dashboard

$additional-news-widget-item-seperation: 1rem !default;

// Page

$page-headline-font-size: 1.5rem !default;
$page-headline-padding-y: 1rem !default;
$page-header-sspacing-y: 3rem !default;

// Overlay

$overlay-headline-font-size: 1.5rem !default;
$overlay-header-height: 6rem !default;
$overlay-header-spacing-y: 2rem !default;

$overlay-actions-spacing-y: 3rem !default;

// Watchlists

$watchlist-list-actions-spacing-y: 1rem !default;

// BWList

$bwlist-overview-section-heading-font-size: 1.35rem !default;
$bwlist-overview-list-item-spacing-y: 0.8rem !default;
$bwlist-overview-inner-list-item-spacing-y: $bwlist-overview-list-item-spacing-y * 0.5 !default;
$bwlist-overview-inner-list-item-spacing-x: 1,5rem !default;

// DPC

$dpc-summary-item-spacing: 1rem !default;

// Carousel

$carousel-control-color:                      $white !default;
$carousel-control-width:                      10% !default;
$carousel-control-opacity:                    .5 !default;

$carousel-indicator-width:                    30px !default;
$carousel-indicator-height:                   3px !default;
$carousel-indicator-spacer:                   3px !default;
$carousel-indicator-active-bg:                $white !default;

$carousel-caption-width:                      70% !default;
$carousel-caption-color:                      $white !default;

$carousel-control-icon-width:                 25px !default;

$carousel-control-prev-icon-bg: url("/assets/img/icons/caret-left.svg") !default;
$carousel-control-next-icon-bg: url("/assets/img/icons/caret-right.svg") !default;

$carousel-transition:           transform .6s ease-in-out !default;

// Widgets

$overlay-table-widget-spacing-y: 1rem;

// Table
$custom-entity-table-row-selected-bg: scale_color($brand-primary, $lightness: 80%, $saturation: -80%);
