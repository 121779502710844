.noUi-tooltip {
    display: none;
}
.noUi-active .noUi-tooltip {
    display: block;
}

.slider-styled,
.slider-styled .noUi-handle {
    box-shadow: none;
}

.slider-styled .noUi-handle .noUi-touch-area {
    border: 1px solid transparent;
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    width: auto;
    height: auto;
}


/* Hide markers on slider handles */
.slider-styled .noUi-handle::before,
.slider-styled .noUi-handle::after {
    display: none;
}

.slider-round > .noUi-target {
    height: 10px;
}

.slider-round .noUi-handle {
    height: 18px;
    width: 18px;
    top: -5px;
    right: -9px; /* half the width */
    border-radius: 9px;
}
