.watchlist-list {

    &__share-type {

        &--shared-public {
            color: $brand-success;
        }

        &--shared-private {
            color: $brand-danger;
        }

    }

    &__actions {
        display: flex;
        margin: $watchlist-list-actions-spacing-y 0;
    }

    &__table {
        border-collapse: separate;
        border-spacing: 0;

        thead {

            th {
                vertical-align: top;
            }

            background: $white;
        }

        tbody {
            tr {
                transition: background-color 0.3s ease-in-out;

                &:first-child td {
                    border-top: none;
                }


                td {
                    vertical-align: middle;
                    cursor: pointer;

                    &.watchlist-description {
                        width: 50%;
                    }
                }

                td:last-child {
                    cursor: auto;
                }
            }
            tr:hover {
                background-color: $dericon-light-grey;
            }
        }
    }
}

.watchlist-details {
    display: block;
    padding-bottom: 2rem;

    &__description-input {
        resize: none;
        min-height: 12rem;
    }

    &__actions {
        vertical-align: bottom !important;
    }

    &__name,
    &__shares,
    &__rights {
        width: 15%;
    }

    &__product-count {
        width: 10%;
    }

    &__description {
        width: 25%;
    }

    &__actions {
        white-space: nowrap;
        width: 20%;
    }

    &__shared-by {
        color: $text-muted;
        margin-bottom: 1rem;
    }

    &__shared-by-label {

        &--private {
            color: $brand-success;
        }

        &--shared {
            color: $brand-danger;
        }

    }

    &__table {
        table-layout: fixed;

        td, th {
            padding: 0.75rem 1rem;
        }
    }
}

.watchlist-overlay {
    &__product-group > div {
        background-color: $white;
        padding-top: 1rem;

        h4 {
            display: inline-block;
        }
    }
}

.shareable-target-select {

    $b: &;

    &__share {
        display: flex;
        align-items: flex-start;
        line-height: initial;
        margin-bottom: 0.25rem;

        &--selected {

            #{$b}__share-name {
                text-decoration: underline;
            }

        }
    }

    &__share-right {
        font-size: $font-size-sm;
        color: $text-muted;
    }

    &__remove-button {
        overflow: hidden;

        .mat-icon.material-icon {
            width: 18px;
            height: 18px;
            margin-top: -1px;
            margin-right: 4px;
            color: $brand-danger;
        }
    }

    &__cancel {
        margin-bottom: 1rem;
        margin-top: 0.3rem;
        margin-left: 1.5rem;
        display: inline-block;
    }

}

.shares-list {
    display: block;

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__item {
        margin-bottom: 0.25rem;
    }

    &__share-icon > .mat-icon {
        height: 1rem;
        width: 1rem;
        border: 1px solid;
        border-radius: 50%;
        color: $brand-success;
        vertical-align: top;
    }
}

.watchlist-details-import {

    &__form-actions {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding-bottom: 1.225rem;
    }

    &__table-container {
        max-height: 300px;
        overflow: auto;
    }
}
