.bwlist-overview {
    &__row {
        @include make-row($grid-gutter-widths-large)
    }

    &__rootrow {
        display: flex;
        flex-wrap: wrap;
        margin-left: 0;
        margin-right: -1rem;
    }

    &__sidebar {
        @include make-col-ready($grid-gutter-widths-large);
        @include make-col(2, 10);

        background: $dericon-light-grey;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    &__details {
        @include make-col-ready($grid-gutter-widths-large);
        @include make-col(8, 10);
    }

    &__section {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__section-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 1rem;
        cursor: pointer;
    }

    &__section-heading {
        flex: 1;
        color: $brand-primary;
        font-size: $bwlist-overview-section-heading-font-size;
    }

    &__section-expand-toggle {
        width: 1.25rem;
        height: 1.25rem;
        text-align: center;
        margin-bottom: 1rem;

        &:after {
            display: block;
            font-size: 2rem;
            line-height: 1.5rem;
            content: '›';
            transform: rotate(90deg);
        }

        &--expanded {
            margin-top: 0.25rem;
            transform: scaleY(-1);
        }
    }

    &__list {
        padding: 0;
        margin: 0;
        list-style: none;
        border-top: 1px solid $dericon-medium-light-grey;
        transition: max-height 200ms linear, opacity 200ms linear;
        max-height: 0;
        opacity: 0;
        overflow: hidden;

        &--expanded {
            max-height: 1000px;
            opacity: 1;
            overflow: visible;
        }
    }

    &__inner-list {
        padding: 0 0 $bwlist-overview-inner-list-item-spacing-y $bwlist-overview-inner-list-item-spacing-x;
        margin: 0;
        list-style: none;

    }

    &__inner-list-item {
        border-bottom: none;
    }

    &__list-item {
        border-bottom: 1px solid $dericon-medium-light-grey;
    }

    &__list-item, &__inner-list-item {
        &--active {
            > a {
                font-weight: bolder;
            }
        }
    }

    &__inner-list-item-link,
    &__inner-list-item-group,
    &__list-item-link,
    &__list-item-group {
        display: block;
        padding: $bwlist-overview-list-item-spacing-y 0;
        color: $brand-primary;

        &--active {
            font-weight: bolder;
        }

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
        }

        &:before {
            content: '›';
            margin-right: 0.5rem;
            text-decoration: none;
        }
    }

    &__inner-list-item-link,
    &__inner-list-item-group {
        padding: $bwlist-overview-inner-list-item-spacing-y 0;
    }

    &__header {
        position: relative;

        &__actions-container {
            position: absolute;
            right: 0;
            bottom: 0;

            app-entity-actions {
                margin-bottom: 0 !important;
            }
        }
    }
}


.bwlist-details {
    &__list-type-selector-form {
        margin-bottom: 3rem;
    }

    &__list-type-selector {
        min-width: 16rem;
        margin-right: 0.5rem;
    }

    &__list-type-selector-info {
        display: inline-block;
        height: 0.875rem;
        width: 0.875rem;
        line-height: 0.875rem;
        text-align: center;
        color: $body-color;
        border: 1px solid $body-color;
        font-size: 0.625rem;

        &:before {
            content: 'i'
        }
    }

}
