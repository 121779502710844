.table {
    th,
    td {
        padding: $table-cell-padding $table-cell-padding*0.5;
    }

    th {
        font-weight: $font-weight-normal;
        font-size: $font-size-sm;
        color: $dericon-medium-grey;
        border-top: none;
    }

    td, th {
        &.highlight {
            background-color: $gray-lightest;
        }

        &.selected {
            background-color: $table-border-color;
        }

        &.vmiddle {
            vertical-align: middle!important;

            label.custom-control.custom-switch {
                margin-bottom: 0;
            }
        }
    }

}

.table-xs {
    th, td {
        padding: $table-cell-padding*0.5;
    }
}

.data-table {

    &-cell {

        &--decimal,
        &--percent,
        &--currency,
        &--composite {
            text-align: left;
        }

        td {
            text-align: left;
        }

    }

}

.entity-details-table {

    &__table {
        margin-bottom: 0;

        tr:first-child {
            td, th {
                border-top: none;
            }
        }

        th {
            border-top: $table-border-width solid $table-border-color;
            padding-top: $table-cell-padding + 0.125rem;
            padding-right: 3rem;
            white-space: normal;

            @include media-breakpoint-down('lg') {
                width: 50%;
                white-space: normal;
                word-wrap: break-word;
                word-break: break-all;
            }
        }

        td {
            width: auto;
        }


        &__separator {
            th {
                padding-top: 1rem!important;
                border-top: 0!important;
                color: $body-color!important;
                text-transform: uppercase;

                &:not(:empty) {
                    padding-top: 1.5rem!important;
                    padding-bottom: 0.5rem!important;
                }
            }

            & + tr th, & + tr td  {
                border-top: 0!important;
            }
        }

    }
}

.entity-details-table-cell {
    &--composite {

        .additional {
            font-size: $font-size-sm;
            color: $dericon-medium-grey;
        }

    }

    &--middle {
        vertical-align: middle!important;
    }

    &--loading {
        background-color: $table-border-color;

        app-spinner {
            display: flex;
            justify-content: center;
        }
    }
}

.group-row {
    background-color: $brand-muted;
}
