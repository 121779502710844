.recommendation-overview {

    &__row {
        @include make-row($grid-gutter-widths-large)
    }

    &__sidebar {
        @include make-col-ready($grid-gutter-widths-large);
        @include make-col(2, 10);
    }

    &__details {
        @include make-col-ready($grid-gutter-widths-large);
        @include make-col(8, 10);
    }

    &__section {
        margin-bottom: 3rem;
    }

    &__section-heading {
        color: $brand-primary;
        font-size: $bwlist-overview-section-heading-font-size;
        margin-bottom: 1rem;
    }

    &__list {
        padding: 0;
        margin: 0;
        list-style: none;
        border-top: 1px solid $dericon-medium-light-grey;
    }

    &__list-item {
        border-bottom: 1px solid $dericon-medium-light-grey;
    }

    &__list-item-link {
        display: block;
        padding: $bwlist-overview-list-item-spacing-y 0;
        color: $text-muted;

        &--active {
            color: $brand-primary;
        }

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
        }

        &:before {
            content: '›';
            margin-right: 0.5rem;
            text-decoration: none;
        }
    }

}


.recommendation-details {

    &__list-type-selector-form {
        margin-bottom: 3rem;
    }

    &__list-type-selector {
        min-width: 16rem;
        margin-right: 0.5rem;
    }

    &__list-type-selector-info {
        display: inline-block;
        height: 0.875rem;
        width: 0.875rem;
        line-height: 0.875rem;
        text-align: center;
        color: $body-color;
        border: 1px solid $body-color;
        font-size: 0.625rem;

        &:before {
            content: 'i'
        }
    }

}

.recommendation-entity-table-cell {
    display: flex;

    &__type {
        line-height: $icon-button-size;
        text-align: center;
        margin-right: 0.5rem;

        &:hover,
        &:focus {
            text-decoration: none;
        }

        &--selected {
            background: $brand-primary;
            color: $white;
        }
    }
}
